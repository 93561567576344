import React, { useState } from "react";
import {
    LinearProgress,
    OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";

import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ProfileRegistrationService from "./Locality/Service/profileRegistrationService"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import AdvertisementBanner from "../../components/AdvertisementBanner/AdvertisementBanner"
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";

// styles
// import useStyles from "./styles";
import Alert from '@material-ui/lab/Alert';
import Loader from './Loader';

import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';
import TicketBookingService from "./Locality/Service/ticketBookingService";
// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '100%',
//       '& > * + *': {
//         marginTop: theme.spacing(2),
//       },
//     },
//   }));
export default function Getqr(props) {
    // const classes = useStyles();
    const { bookingId, parkId } = useParams();
    const { parkName, setParkName } = useParams();
    const [loading, setLoading] = useState(true);
    var [error, setError] = useState(null);
    const [mobile, setMobile] = useState('');
    const [advtDetails, setAdvtDetails] = useState([]);

    const [ticketDetails, setTicketDetails] = useState({ ticketDetails: [], message: '', status: false });
    const [ticketBooking, setTicketBookingList] = useState({
        "status": 'info',
        "image": "",
        "message": "",
        "id": ""
    });

    useEffect(() => {
        getAdvByIdList();

        return () => {
            setAdvtDetails([])
        }
    }, []);
    const getQr = () => {
        console.log(mobile)

    }
    const updateMobile = (event) => {
        setMobile(event.target.value)
    }
    const handleRedirect = () => {

        props.history.push(`ticketbooking/${parkId}/${parkName}`)
    };
    const getByMobileList = () => {
        const phoneNumberRegex = /^\d{10}$/;

        if (!mobile) {
            alert("mobile number required");
            return
        }
          if(!phoneNumberRegex.test(mobile)){
            alert("Please enter valid mobile number");
            return
          }
        TicketBookingService.getQRcodeByMobile({ mobile: mobile, parkId:parkId }).then((res) => {
            res.status = true;
            setTicketDetails(res);

            // setTicketBooking(res);

        }).catch((err) => {
            setError(err.message);
        });
    }
    const getAdvByIdList = () => {

        ParkService.getAdvbyById({  parkId:parkId  }).then((res) => {
            if (res) {
                setAdvtDetails(res.advatagement);

            } 



        }).catch((err) => {
            // setError(err.message);
        });
    }
    return (

        <>
            <Box   >
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"

                    style={{ minHeight: '100vh' }}>
                    <form style={{ width: '70%' }} >
                        <div >
                            <Card >
                                <CardHeader />

                                <CardContent>

                                    <Grid item xs={12}>
                                        <TextField
                                            style={{ width: '100%' }}
                                            margin="dense"
                                            id="mobile"
                                            name="mobile"
                                            value={mobile}
                                            onChange={updateMobile}
                                            label="Enter Mobile Number"
                                            type="text"
                                            variant="outlined"

                                        />

                                    </Grid>
                                    {ticketDetails.ticketDetails.length ? <Grid container spacing={3}>

                                        {ticketDetails.ticketDetails.map(({ image }) => (

                                            <Grid item xs={4}>
                                                <div style={{textAlign:"center"}}>


                                                    <div >
                                                        <img src={image} />
                                                    </div>
                                                    <div>
                                                        <a href={image} download>Download</a>
                                                    </div>
                                                    </div>
                                            </Grid>

                                        ))}
                                    </Grid> : ticketDetails.status ? <Alert severity="info">{ticketDetails.message}</Alert> : ""
                                    }


                                </CardContent>

                                <div style={{ marginTop: "2%" }}>
                                    <Grid item xs={12}>
                                        <div style={{ textAlign: 'center' }}>   
   
                                            <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} onClick={getByMobileList} variant="contained" >Get QR</Button>
                                        </div>
                                    </Grid>
                                </div>
                                <div>
                                
      
      

{advtDetails.map(({ advatagementName,imgURL,urlLink,desc },index) => (

<AdvertisementBanner key={index}
imageUrl={imgURL.length ? imgURL[0]:''}
heading={advatagementName}
description={desc}
buttonText="Shop Now"
buttonUrl={urlLink}
/>

))}
                                </div>
                            </Card>
                        </div>


                    </form>
                </Grid>
            </Box>

        </>
    );
}


