import requests from './httpService';
const BranchService = {
  getAllBranch() {
    return requests.get(`/branch/list`);
  },
  qrCode(id) {
    return requests.get(`/branch/qr/${id}` );
  },
  getBranch(body){
    return requests.post('/branch/get',body); 
  },
  creteBranch(body){
    return requests.post('/branch/add',body); 
  },
  getAllBranchById(id,body){
    return requests.post(`/branch/list/${id}`,body); 
  },
  deleteBranch(body){
    return requests.delete(`/branch/${body._id}`); 
    
  },


  
  getBranchById(id) {
    return requests.get(`/branch/${id}` );
  },

  upadeBranch(body) {
    return requests.put(`/branch/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/branch/listbyprofileid`,body);
  },
  getAppointmentDetailsById(body) {
    return requests.post(`/loanbooking/getqrcodebystatus`,body );
  },
};

export default BranchService;
