import requests from './httpService';
const DoctorService = {
  getAllDoctor() {
    return requests.get(`/doctor/list`);
  },
  getDoctor(body){
    return requests.post('/doctor/get',body); 
  },
  creteDoctor(body){
    return requests.post('/doctor/add',body); 
  },
  getAllDoctorById(id,body){
    return requests.post(`/doctor/list/${id}`,body); 
  },
  deleteDoctor(body){
    return requests.delete(`/doctor/${body._id}`); 
    
  },
  
  getDoctorById(id) {
    return requests.get(`/doctor/${id}` );
  },
  getClinicById(id) {
    return requests.get(`/clinic/${id}` );
  },

  upadeDoctor(body) {
    return requests.put(`/doctor/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/doctor/listbyprofileid`,body);
  },

  getQRcodebyById(body) {
    return requests.post(`/doctorbooking/getqrcodebystatus`,body );
  },

};

export default DoctorService;
