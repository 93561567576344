import requests from './httpService';
const AdvatagementService = {
  getAllAdvatagement() {
    return requests.get(`/advatagement/list`);
  },
  getAdvatagement(body){
    return requests.post('/advatagement/get',body); 
  },
  creteAdvatagement(body){
    return requests.post('/advatagement/add',body); 
  },
  deleteAdvatagement(body){
    return requests.delete(`/advatagement/${body._id}`); 
  },
  getAdvatagementById(id) {
    return requests.get(`/avatagement/${id}` );
  },
  upadeAdvatagement(body) {
    return requests.put(`/advatagement/${body._id}`,body); 
  }, 
};

export default AdvatagementService;
