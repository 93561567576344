import requests from './httpService';
const ImageService = {
//   getAllSound() {
//     return requests.get(`/sound/list`);
//   },
//   creteSound(body){
//     return requests.post('/sound/add',body); 
//   },
//   deleteSound(body){
//     return requests.delete(`/sound/${body._id}`); 
//   },
//   findSoundList(body){
//     return requests.post(`/sound/find`,body); 
//   },
//   upadeSound(body) {
//     return requests.put(`/sound/${body._id}`,body); 
//   },
  uploadImage(body){
    return requests.post('/image/data/upload',body); 
  },
};
export default ImageService;
