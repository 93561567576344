import React, { useState,useRef } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table, List, ListItem, Checkbox, FormControlLabel,
    TableHead,
    TableBody, Card,
    TableCell,
    FormLabel, FormGroup, FormHelperText
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import poster from "../../../src/images/poster.jpg"
import ExbhitionService from "./Locality/Service/exbhitionService";
import ImageService from "./Locality/Service/imageService";
import * as Yup from 'yup';
import TicketBookingService from "./Locality/Service/ticketBookingService";
import Canvas from './Canvas'
import { Typography } from "../../components/Wrappers/Wrappers";
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Exbhition() {
    const tableHeaders = ['Organization Name', 'Type', 'Location',, 'From date ','To date','Edit', 'Delete', 'QR code'];
    const classes = useStyles();
    const canvasRef = useRef(null);
    const [exbhitionList, setExbhitionList] = useState([]);
    const [getExbhitionsList, setExbhitions] = useState([]);
    const [qrCode, setQrCode] = useState([]);
    const [open, setOpen] = React.useState(false);
    var [uploadError, setUploadError] = useState({ isShowError: false, successCount: 0, error: [], message: "" });
    const [pg, setpg] = React.useState(0);
    const [formData, setFormData] = useState("");
    const [rpg, setrpg] = React.useState(5);
    // const [isOpen, setIsOpen] = useState(false);
    // const [open1, setOpen1] = React.useState(false);
    const [ticketBookingList, setTicketBookingList] = useState([]);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });
    const [exbhition, setExbhition] = useState({
        organizationName: '',
        location: '',
        exhibition:'',
        date: '',
        time: '',
    });
    const validationSchema = Yup.object().shape({
        organizationName: Yup.string(),
        //.required('Organization Name is required'),
        exhibition: Yup.string(),
        // .required('Type is required'),
        location: Yup.string(),
        // .required('location is required'),
        date: Yup.string(),
        // .required('date and time is required'),
        time: Yup.string(),
        // .required('date and time is required'),
    });
   
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
       
    };
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
  
    useEffect(() => {
      
       getProfileId();
       
        return () => {
            setExbhitionList([]);
            setQrCode([]);
           
        }
    }, []);
    const draw = (data) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
  
        // Load the base image
        const baseImage = new Image();
        baseImage.onload = () => {
          ctx.drawImage(baseImage, 0, 0, canvas.width, canvas.height);
  
          // Load the image to be displayed on top
          const topImage = new Image();
          topImage.onload = () => {
            // Adjust the position of the top image as desired
            const x = 50; // X coordinate
            const y = 160;  // Y coordinate
            ctx.drawImage(topImage, x, y, topImage.width, topImage.height);
            ctx.font = 'bold 30px  Arial';
            ctx.fillStyle = 'black';
            ctx.textAlign = "center";
            const imgcenter = canvas.width / 2;
            ctx.fillText(data.organizationName, imgcenter, 50);
          };
          topImage.src = data.image; // Replace 'top_image.jpg' with your top image file
        };
        baseImage.src = poster ; // Replace 'base_image.jpg' with your base image file
       setTimeout(() => {
        handleDownload(data.organizationName);
       }, 1500);
        
      };
   
      const handleDownload = (organizationName) => {
        const canvas = canvasRef.current;
        const url = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = url;
        a.download = organizationName+'.png';
        a.click();
      };
    
    const getTicketBookingList = () => {
        TicketBookingService.getAllTicketBooking().then((res) => {
            setTicketBookingList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getProfileId = ()=>{
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
console.log(userDetails.role)

   const result = userDetails.role==="admin" ? null:userDetails.profileId;
   console.log(result)
        ExbhitionService.addAllProfileId({profileRegistrationId:result }).then((res) => {
            setExbhitionList(res);
    }).catch((err) => {
        // setError(err.message);
    });
}
    const getQrCode = (data) => {
       
        ExbhitionService.qrCode(data._id).then((res) => {
            
            if(res){
                const base64Data = res.image.replace(/^data:image\/\w+;base64,/, '');
                const imageBuffer = Buffer.from(base64Data, 'base64');
                // Create a blob URL for the binary buffer
                const blob = new Blob([imageBuffer]);
                const blobUrl = URL.createObjectURL(blob);
                // Create a link element and trigger the download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = data.organizationName+'.png'; // Change the file name and extension
                link.click();
            
                // Clean up the blob URL
                URL.revokeObjectURL(blobUrl);

            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getPoster = (data) => {
    //     ExbhitionService.qrCode(data._id).then((res) => {
            
    //         if(res){
    //      res.parkName=data.parkName.toUpperCase();
    //             draw(res);

    //         }
    //     }).catch((err) => {
           
    //     });
    // }
    const uploadFile = () => {
        ImageService.uploadImage(formData).then((res) => {
            alert(res.message);
            res.isShowError = true;
            setUploadError(res)
           
        })
    };
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
    const onclick = () => {
        setOpen(true);
    }
    
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
    const text = (
        <div  className={classes.paper}  style={{height:"100%", width:"100%"}}>
         
          
         
          <canvas ref={canvasRef} width={600} height={800}></canvas>
          
        </div>
      );
    const editExbhition = (exbhition) => {
        
        setExbhition(exbhition);
        handleOpen();
    }
 
    const deleteExbhition = (exbhitiondelete) => {
        if (exbhitiondelete) {
            ExbhitionService.deleteExbhition(exbhitiondelete).then((res) => {
                //getParkList();
                getProfileId()
                onSubmit()
            }).catch((err) => {
            });
        }
    };

    const formik = useFormik({
        initialValues: exbhition,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            debugger
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            
            if (exbhition._id) {
                ExbhitionService.upadeExbhition(values).then((res) => {
                    handleClose();
                  //  getParkList();
                  getProfileId();
                    resetForm()
                    alert("Exbhition Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                
                console.log(values)
                

                ExbhitionService.creteExbhition(values).then((res) => {
                    //getParkList();
                    getProfileId();
                    resetForm();
                    handleClose();
                    alert(" Exbhition Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
        
            <PageTitle title="Shows" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Shows
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {exbhitionList.slice(pg * rpg, pg * rpg + rpg).map((exbhition) => (
                                    <TableRow key={exbhition._id}>
                                        <TableCell className="pl-3 fw-normal" >{exbhition.organizationName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{exbhition.exhibition}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{exbhition.location}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{exbhition.date}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{exbhition.time}</TableCell>

                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editExbhition(exbhition)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteExbhition(exbhition)} />
                                        </TableCell>
                                        <TableCell>
                                        <Button variant="contained" onClick={() => getQrCode(exbhition)} size="medium" color="secondary" 
                                        style={{ backgroundColor: '#ff6b81' }}> Download </Button>
                                            {/* <QrCodeIcon style={{ cursor: 'pointer' }} onClick={() => getQrCode(park)} /> */}
                                        </TableCell>
                                        
                                        {/* <TableCell>
                                        <Button variant="contained" onClick={() => handleOpen1(exbhition)} size="medium" color="secondary" 
                                        style={{ backgroundColor: '#ff6b81' }}> Poster </Button>
      
                                        </TableCell> */}
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 100, 1000]}
                            count={exbhitionList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Show</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 650 }}>
                        <TextField
                            style={{ width: 300 }}
                            id="organizationName"
                            name="organizationName"
                            label="Organization Name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.organizationName}
                            error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                            helperText={formik.touched.organizationName && formik.errors.organizationName}
                        />
                        <br></br>
                        <br></br>
                       
                                              <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Select Type:</FormLabel>
      <RadioGroup
        row
        name="exhibition" 
        margin="dense"
      id="exhibition" 
       type="radio"
      variant="standard"
      value={formik.values.exhibition}
      onChange={formik.handleChange}
      error={formik.touched.exhibition && Boolean(formik.errors.exhibition)}
      helperText={formik.touched.exhibition && formik.errors.exhibition}
   
      >
        <FormControlLabel name="exhibition" value="exhibition " control={<Radio />} label="Exhibition " />
        <FormControlLabel name="exhibition" value="shows " control={<Radio />} label="Shows " />
        <FormControlLabel name="exhibition" value="drama" control={<Radio />} label="Drama" />
      </RadioGroup>
    </FormControl>
                        <TextField
                            style={{ width: 300 }}
                            id="location"
                            name="location"
                            label="Location"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.location}
                            error={formik.touched.location && Boolean(formik.errors.location)}
                            helperText={formik.touched.location && formik.errors.location}
                        />
     <br></br>
    <TextField InputProps={{ style: { width: 300 } }}
                id="date"
                name="date"
                // autoFocus
                label="Select  Date"
                type="datetime-local"
                value={formik.values.date}
                onChange={formik.handleChange}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />   
              <br></br>
                      <TextField InputProps={{ style: { width: 300 } }}
                id="time"
                name="time"
                autoFocus
                label="Select  Date"
                type="datetime-local"
                value={formik.values.time}
                onChange={formik.handleChange}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />   
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button  onClick={formik.handleSubmit} >Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
           
        </>
    );

}


