import React, { useState } from "react";
import { Button, Card, Box } from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import { Grid, TextField, FormControl, FormControlLabel, FormLabel, InputLabel, Select, MenuItem, } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Radio from '@material-ui//core/Radio';
import RadioGroup from '@material-ui//core/RadioGroup';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useParams } from "react-router-dom";
import LoanBookingService from "./Locality/Service/loanBookingService";
import DoctorService from "./Locality/Service/doctorService";
import BranchService from "./Locality/Service/branchService";
import BranchUserService from "./Locality/Service/branchUserService";
import SpecilizationService from "./Locality/Service/specilizationService";
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import Footer from "../../pages/home/Footer"
import Loader from './Loader';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: 'rgb(255, 107, 129)',
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },

});

export default function LoanBooking(props) {

  const loanList1 = ['Auto mobile', 'Personal', 'Consumer durables ', 'Home'];
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [specilizationList, setSpecilizationList] = useState([]);
  const [typeOfLoan, setTypeOfLoan] = useState('');
  const [booking1, setBooking1]= useState();
  const [loanBooking, setLoanBooking] = useState({
    // name: '',
    // typeOfLoan: '',
    counterNumber:'',
    branchId: '',
    branchUserId:'',
    branchName: '',
    // doctorName:'',
     profileRegistrationId: '',
    // specilizationId: '',
    // mobile: '',
    // fee: '',


  });
  const [loanList, setLoanList] = useState([]);

  const [totalSum, settotalSum] = useState(0);

  const { branchuserId } = useParams();
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [loanBookingList, setLoanBookingList] = useState([]);
  const [profileRegistrationId, setProfileRegistrationId] = useState('');
  var [error, setError] = useState(null);
  const [fee, setFee] = useState([]);
  //   const [specilizationList, setSpecilizationList] = useState([]);
  const [paymentMode1, setPaymentMode1] = useState('online');
  const validationSchema = Yup.object().shape({
    branchId: Yup.string().required('branch Name is required'),
    mobile: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
    name: Yup.string().required(' Name is required'),
    typeOfLoan: Yup.string().required(' Loan type is required'),

  });

  const getDay = () => {
    const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();

    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const currentDayName = daysOfWeek[currentDayNumber];
    return currentDayName;
  }

  const bookTickets = () => {

    const day = getDay();
    const phoneNumberRegex = /^\d{10}$/;
    if (!mobile) {
      alert("Please enter mobile number");
      return
    }
    if (!phoneNumberRegex.test(mobile)) {
      alert("Please enter valid mobile number");
      return
    }
    const ticketDetails = {
      // fee: getBookedDetails,
    
      branchName: loanBooking.branchName,
      branchId: loanBooking._id,
      mobile: mobile,
      name: name,
      typeOfLoan:typeOfLoan,
      profileRegistrationId: loanBooking.profileRegistrationId,
    }
    setLoading(true);

    LoanBookingService.creteLoanBooking(ticketDetails).then((res) => {
      setName('');
      setMobile('');
      setTypeOfLoan('');
      handleRedirect(res.id,res.branchUserId);
      setLoading(false);
      //payment({ mobile: mobile, name:name, typeOfLoan:typeOfLoan, id: res.id })

    })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.message)
      })
  }
  const handleOpens = () => {
    props.history.push(`/getqr/${branchuserId}`)
  };
   const paymentMode = (paymentMode) => {

     setPaymentMode1(paymentMode);
  };
  const sumOfTotal = () => {
    console.log(JSON.stringify(fee));
    const totalPrice = fee.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    console.log(totalPrice)
  }
  // const getSpecilizationList = (registerId) => {
  //   const specilizationI = { profileRegistrationId: registerId };
  //   SpecilizationService.getAllSpecilization(specilizationI).then((res) => {
  //     setSpecilizationList(res);

  //   }).catch((err) => {

  //     // setError(err.message);
  //   });
  // }
  // const getProfileId = (profileRegistrationId) => {

  //   BranchService.addAllProfileId({ profileRegistrationId: profileRegistrationId }).then((res) => {

  //     setLoanList(res);

  //   }).catch((err) => {

  //   });
  // }
  const pricePerItem = 10;
  useEffect(() => {
    getByIdList();
   
    //  sumOfTotal();
    return () => {
      // setSpecilizationList([]);
    }
  }, []);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  function isObj(val) {
    return typeof val === 'object'
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })

    return form
  }
  function post(details) {
    const form = buildForm(details)
    console.log(form);
    document.body.appendChild(form)
    form.submit()
    form.remove()
    setLoading(false);
  }
  const payment = (values) => {
    LoanBookingService.paymentProcess(values).then((response) => {
      var information = {
         action: process.env.REACT_APP_CCAV_URL,
        params: response
      }
      post(information);
    })
  }

  const updateMobile = (event) => {
    setMobile(event.target.value)
  }
  const updateTypeOfLoan = (event) => {
    setTypeOfLoan(event.target.value)
  }
  const updateName = (event) => {
    setName(event.target.value)
  }
  const getByIdList = () => {
    setLoading(true);
    BranchUserService.getBranchById(branchuserId).then((res) => {
      const branchUser = [];
      if (res) {
        setLoading(false);
        setProfileRegistrationId(res.profileRegistrationId);
        setLoanBooking({
          branchName:  res.branchName,
          branchId: res._id,
          // branchUserId: res._id, 
         // counterNumber: res.counterUsername, 
          profileRegistrationId: res.profileRegistrationId ,
        })
        // getProfileId(res.profileRegistrationId);
        setLoanBooking(res);
      }
    }).catch((err) => {
      setError(err.message);
    });
  }

  const handleRedirect = (id, branchuserId) => {

    props.history.push("/loanappointment/" + id+"/"+ branchuserId);
  };
  const booking = () => {
    setBooking1();
  };
  const formik = useFormik({
    initialValues: loanBooking,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // values.paymentMode = paymentMode1
      LoanBookingService.creteLoanBooking(values).then((res) => {

        // alert(res.message);
        console.log(res)
        handleRedirect(res.id, res.branchUserId);
        // paymentMode1();
        // props.history.push('/montessori/login');
      })
        .catch((err) => {
          alert(err.response.data.message);
        })
    },
  });

  // const totalSum = fee.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <>
      {loading ? <Loader /> :


        <Box   >
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"

            style={{ minHeight: '100vh' }}>
            <form onSubmit={formik.handleSubmit} class="form" style={{ width: '100%', margin: '15px' }} >
              <div >
                <Card >
                  <CardHeader
                    avatar={

                      <LocationOnIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }


                    classes={{
                      title: classes.title
                    }}
                    title="Branch"

                  />
                  

                  <CardContent>
                    <Grid item xs={12} >
                      <TextField
                        margin="dense"
                        id="branchName"
                        name="branchName"
                        value={loanBooking.branchName}
                        label="Name Of The Branch"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Grid>

                  </CardContent>
                </Card>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Card >

                  <CardHeader
                    avatar={

                      <ConfirmationNumberIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }
                    classes={{
                      title: classes.title
                    }}
                    title="Appointment With"

                  />
                  <CardContent>
                      <Grid item xs={12}>
                        <TextField
                          style={{ width: '100%' }}
                          margin="dense"
                          id="name"
                          name="name"
                          value={name}
                          onChange={updateName}
                          label="Enter Full Name"
                          type="text"
                          variant="outlined"

                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField autoFocus
                          style={{ width: '100%' }}
                          margin="dense"
                          id="mobile"
                          name="mobile"
                          value={mobile}
                          onChange={updateMobile}
                          label="Enter Mobile Number"
                          type="text"
                          variant="outlined"

                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth="true" >
                          <InputLabel id="demo-simple-select-standard-label">Type Of Loan</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            InputProps={{ style: { width: 258 } }}

                            margin="dense"
                            name="typeOfLoan"
                            label="Type Of Loan"
                            type="text"
                            variant="standard"
                            value={typeOfLoan}
                            onChange={updateTypeOfLoan}
                            
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>




                            {loanList1.map((loanList1) => (
                              <MenuItem key={loanList1} selected={loanList1 === 'Nursery'} value={loanList1}>
                                {loanList1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    
                  </CardContent>


                </Card>
              </div>
              
              <Grid item xs={12}>
                <div style={{ marginTop: "2%" }}>
                  <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }}
                      onClick={(e)=>bookTickets()} variant="contained" >book</Button>
                  </div>

                </div>
              </Grid>
              <div style={{ marginTop: "2%" }}>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'left' }}>
                    {/* <a href="javascript:void(0)" class="btn" ><i class="lni lni-apple"></i> Book More Tickets</a> */}
                    {/* <PublicRoute path="/ticketbooking/:parkId" component={TicketBooking} /> */}

                    <a style={{ fontWeight: 'bold', color: '#ff6b81', fontSize: 'large' }} href="javascript:void(0)" 
                    onClick={handleOpens} class="page-scroll active">
                      Get QR </a>
                    <p>(In case QR not generated after payment, click here)</p>
                  </div>
                </Grid>
              </div>
              {/* <Grid item xs={12}>
                <div style={{ marginTop: "2%" }}>

                  <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} type="submit" variant="contained" >Book</Button>
                  </div>

                </div>
              </Grid> */}
            </form>
          </Grid>
        </Box>}
      <Footer link="https://www.ccavenue.com/terms_conditions.jsp#:~:text=Subject%20to%20the%20terms%20of,handling%20fee%2C%20express%20payout%20charges%2C"></Footer>
    </>
  );
}


