import React, { useState } from "react";
import {
  LinearProgress,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell
} from "@material-ui/core";
import ProfileRegistrationService from "./Locality/Service/profileRegistrationService"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// styles
import useStyles from "./styles";
// components
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';
import Footer from "../../pages/home/Footer"
import Header from "../../pages/home/Header"

export default function Privacy(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  var [error, setError] = useState(null);
  const [constituencyList, setConstituencyList] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [profileRegistrationList, setProfileRegistrationList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [profileRegistration, setProfileRegistration] = useState({
    registrationType:'park',
    location: '',
    name: '',
    email: '',
    personName:'',
    mobileNumber1: '',
    mobileNumber2: '',
    password: '',
  });
  const handleChange = (event) => {
    setAge(event.target.value);
};
  const validationSchema = Yup.object().shape({
    location: Yup.string().required('location  is required'),
    registrationType:Yup.string().required('Registration Type  is required'),
    name: Yup.string().required('orgnaization name is required'),
    email: Yup.string().required('email is required'),
    personName: Yup.string().required('Name is required'),
    mobileNumber1: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
      mobileNumber2: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
      password: Yup.string().required('password is required'),
  });
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

 

  const handleFormSubmit = (event) => {
    event.preventDefault();

  };
  const getProfileRegistrationList = () => {
    ProfileRegistrationService.getAllProfileRegistration().then((res) => {
      setProfileRegistrationList(res);
    }).catch((err) => {
      setError(err.message);
    });
  }
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    return () => {
    }
}, []);

  const formik = useFormik({
    initialValues: profileRegistration,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
        ProfileRegistrationService.creteProfileRegistration(values).then((res) => {

        alert(" Registration Successfully.");
         props.history.push('/sriqr/login');
      })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
  });


  return (

    <>
    <Header></Header>
    <section>
    <Grid item xs={12}>
          <Card sx={{ maxWidth: 345 }}>
            <Box   >
              
              <div style={{ marginLeft: "7%" }}>
                <form onSubmit={formik.handleSubmit} >
                
                  <Grid container spacing={2} columns={12} >
                    <Grid item xs={12}>
                      <PageTitle InputProps={{ style: { color: '#10b680' } }} title="Privacy Policy" > Privacy Policy</PageTitle>
                      <h5>This privacy notice for Srshta Tech Solutions Pvt Ltd ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share
                         ('process') your information when you use our services ('Services'), such as when you:</h5>
                         <br></br>
                         <li>	Visit our website at sriqr.com, or any website of ours that links to this privacy notice</li>
                         <li>	Engage with us in other related ways, including any sales, marketing, or events</li>
                         <br></br>
                         <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, 
                            please do not use our Services. If you still have any questions or concerns, please contact us at amar@Srshta.com.</p>
                            <br></br>
<h4>SUMMARY OF KEY POINTS</h4>
<br></br>
<p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for:</p>
<br></br>
<p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>
<br></br>
<p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>
<br></br>
<p>Do we receive any information from third parties? We do not receive any information from third parties</p>
<br></br>
<p>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</p>
<br></br>
<p>we collect mobile number of user while buying tickets to retrieve ticket in case it’s not generated after successful payment. User generates tickets by entering mobile number which was entered while buying tickets.</p>
<br></br>
<p>In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</p>
<br></br>
<p>How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</p>
<br></br>
<p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights</p>
<br></br>
<p>How do you exercise your rights? The easiest way to exercise your rights is  by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
<br></br>
<p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>
<br></br>
<h5>TABLE OF CONTENTS</h5>
<br></br><br></br>
<p>1. WHAT INFORMATION DO WE COLLECT?</p>
<p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
<p>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
<p>4. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
<p>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
<p>6. DO WE COLLECT INFORMATION FROM MINORS?</p>
<p>7. WHAT ARE YOUR PRIVACY RIGHTS?</p>
<p>8. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
<p>9. DO WE MAKE UPDATES TO THIS NOTICE?</p>
<p>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
<p>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
<br></br>
<h6>1. WHAT INFORMATION DO WE COLLECT?</h6>
<br></br>
<p>Personal information you disclose to us</p>
<br></br>
<p>In Short: We collect personal information that you provide to us.</p>
<br></br>
<p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
<br></br>
<p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
<br></br>
<li>	phone numbers</li>
<br></br>
<p>Sensitive Information. We do not process sensitive information.</p>
<br></br>
<p>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by CC Avenue. You may find their privacy notice link(s) here: https://www.ccavenue.com/privacy.jsp. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information</p>
<br></br>
<h6>2. HOW DO WE PROCESS YOUR INFORMATION?</h6>
<br></br>
<p>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
<br></br>
<p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
<br></br>
<li>	we collect mobile number of user while buying tickets to retrieve ticket in case it’s not generated after successful payment. User generates tickets by entering mobile number which was entered while buying tickets.</li>
<br></br>
<li>	To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
<br></br>
<h5>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
<br></br>
<p>In Short: We may share information in specific situations described in this section and/or with the following third parties.</p>
<br></br>
<p>We may need to share your personal information in the following situations:</p>   
<br></br>
<h5>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>   
<br></br>     
        <p>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>
        <br></br>
        <p>We will only keep your mobile number information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than   one (1) months past the termination of the user's account.</p>
        <p>When we have no ongoing legitimate business need to process your mobile number information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
        <br></br>
        <h5>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>
        <br></br>
        <p>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</p>
        <br></br>
        <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
        <br></br>
        <h5>6. DO WE COLLECT INFORMATION FROM MINORS?</h5>
        <br></br>
        <p>In Short: We do not collect information on age of user, we collect mobile number of user while buying tickets to retrieve ticket in case it’s not generated after successful payment. User generates tickets by entering mobile number which was entered while buying tickets.</p>
        <br></br>
        <h5>7. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <br></br>
        <p>In Short:  You may review, change, or terminate your account at any time.</p>
        <br></br>
        <p>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>
        <br></br>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
        <br></br>
        <h5>Account Information</h5>
        <br></br>
        <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
        <br></br>
        <li>	Log in to your account settings and update your user account.</li>
        <br></br>
        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
        <br></br>
        <p>If you have questions or comments about your privacy rights, you may email us at amar@Srshta.com.</p>
        <br></br>
        <h5>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
        <br></br>
        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
        <br></br>
        <h5>9. DO WE MAKE UPDATES TO THIS NOTICE?</h5>
        <br></br>
        <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        <br></br>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
        <br></br>
        <h5>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
        <br></br>
        <p>If you have questions or comments about this notice, you may email us at amar@Srshta.com or contact us by post at:</p>
        <br></br>
        <h6>Srshta Tech Solutions Pvt Ltd</h6>
        <h6>E-212</h6>
        <h6>jains carlton Creek</h6>
        <h6>Hyderabad(Telangana), Telangana 500089</h6>
        <h6>India</h6>



 </Grid>
                  </Grid>
              

                </form>
              </div>
            </Box>

          </Card>
       
      </Grid>
    </section>
      
      <Footer color="primary" className={classes.copyright}>
                © 2014-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://flatlogic.com" rel="noopener noreferrer" target="_blank">Flatlogic</a>, LLC. All rights reserved.
            </Footer>
    </>
  );
}


