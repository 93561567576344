import requests from './httpService';
const ClinicService = {
  getAllClinic() {
    return requests.get(`/clinic/list`);
  },
  qrCode(id) {
    return requests.get(`/clinic/qr/${id}` );
  },
  getClinic(body){
    return requests.post('/clinic/get',body); 
  },
  creteClinic(body){
    return requests.post('/clinic/add',body); 
  },
  getAllClinicById(id,body){
    return requests.post(`/clinic/list/${id}`,body); 
  },
  deleteClinic(body){
    return requests.delete(`/clinic/${body._id}`); 
    
  },
  getClinicById(id) {
    return requests.get(`/clinic/${id}` );
  },

  upadeClinic(body) {
    return requests.put(`/clinic/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/clinic/listbyprofileid`,body);
  },
};

export default ClinicService;
