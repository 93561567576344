import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell
} from "@material-ui/core";
import * as Yup from 'yup';
import { Grid, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ClinicService from "./Locality/Service/clinicService";
import DoctorService from "./Locality/Service/doctorService";
// import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Clinic() {
    const tableHeaders = ['Clinic Name','Doctor Name', 'City', 'Area', 'Address', 'Fee', 'specilization','tokens','startTime','endTime','Edit', 'Delete', 'QR code'];
    const classes = useStyles();
    const [clinicList, setClinicList] = useState([]);
    const [age, setAge] = React.useState('');
    const [clinicIdList, setClinicIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [doctorList, setDoctorList] = useState([]);
    const [qrCode, setQrCode] = useState([]);
    const [clinic, setClinic] = useState({
        clinicName:'',
        doctorName: '',
        city: '',
        area: '',
        address: '',
        startTime: '',
        endTime: '',
        fee: '',
        specilization: '',
        tokens: '',
    })

    const validationSchema = Yup.object().shape({
        clinicName:Yup.string().required('clinic Name is required'),
        doctorName: Yup.string().required('doctor name is required'),
        city: Yup.string().required('city is required'),
        area: Yup.string().required('area is required'),
        address: Yup.string().required('address is required'),
        startTime: Yup.string().required('startTime is required'),
        endTime: Yup.string().required('endTime is required'),
        fee: Yup.string().required().matches(/^[0-9]+$/, "Must be only digits"),
        specilization:Yup.string(),
        tokens:Yup.string().required().matches(/^[0-9]+$/, "Must be only digits"),
    });
    useEffect(() => {
        // getClinicList();
        // getDoctorList();
        getProfileId();
        return () => {
            setClinicIdList([]);
            setClinicList([]);
            // setDoctorList([]);
            // setDoctorIdList([]);
            setQrCode([]);
        }
    }, []);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // const getDoctorList = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));

    //     const result = userDetails.role === "admin" ? null : userDetails.profileId;

    //     const specilizationI = { profileRegistrationId: result };


    //     DoctorService.getDoctor(specilizationI).then((res) => {

    //         setDoctorList(res);
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
     const getClinicList = () => {
        ClinicService.getAllClinic().then((res) => {
            setClinicList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getProfileId = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        ClinicService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            setClinicList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }
    const editClinic = (clinic) => {
        const obj = JSON.parse(JSON.stringify(clinic));
        obj.doctorId = clinic.doctorId ? clinic.doctorId._id : '';
        setClinic(obj);
        handleOpen();
    }
    const deleteClinic = (clinicdelete) => {
        if (clinicdelete) {
            ClinicService.deleteClinic(clinicdelete).then((res) => {
                // getClinicList();
                getProfileId()
            }).catch((err) => {
            });
        }
    };
    const getQrCode = (data) => {
    
        ClinicService.qrCode(data._id).then((res) => {
            
            if(res){
                const base64Data = res.image.replace(/^data:image\/\w+;base64,/, '');
                const imageBuffer = Buffer.from(base64Data, 'base64');
                // Create a blob URL for the binary buffer
                const blob = new Blob([imageBuffer]);
                const blobUrl = URL.createObjectURL(blob);
                // Create a link element and trigger the download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = data.clinicName+'.png'; // Change the file name and extension
                link.click();
            
                // Clean up the blob URL
                URL.revokeObjectURL(blobUrl);

            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const formik = useFormik({
        initialValues: clinic,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            if (clinic._id) {
                ClinicService.upadeClinic(values).then((res) => {
                    handleClose();
                    getProfileId();
                    //getClinicList();
                    resetForm()
                    alert("Clinic Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                ClinicService.creteClinic(values).then((res) => {
                    //getClinicList();
                    getProfileId();
                    resetForm();
                    handleClose();
                    alert(" Clinic Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (

        <>
            <PageTitle title="Clinic" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Clinic
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clinicList.map((clinic) => (
                                    <TableRow key={clinic._id}>
                                         <TableCell className="pl-3 fw-normal" >{clinic.clinicName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.doctorName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.city}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.area}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.address}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.fee}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.specilization}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.tokens}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{clinic.startTime}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{clinic.endTime}</TableCell>
                                        
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editClinic(clinic)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteClinic(clinic)} />
                                        </TableCell>
                                        <TableCell>
                                        <Button variant="contained" onClick={() => getQrCode(clinic)} size="medium" color="secondary" 
                                        style={{ backgroundColor: '#ff6b81' }}> Download </Button>
                                            {/* <QrCodeIcon style={{ cursor: 'pointer' }} onClick={() => getQrCode(park)} /> */}
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Clinic</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                    <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="clinicName"
                            name="clinicName"
                            label="Clinic Name"
                            type="text"
                            variant="standard"
                            value={formik.values.clinicName}
                            onChange={formik.handleChange}
                            error={formik.touched.clinicName && Boolean(formik.errors.clinicName)}
                            helperText={formik.touched.clinicName && formik.errors.clinicName}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="doctorName"
                            name="doctorName"
                            label="Doctor Name"
                            type="text"
                            variant="standard"
                            value={formik.values.doctorName}
                            onChange={formik.handleChange}
                            error={formik.touched.doctorName && Boolean(formik.errors.doctorName)}
                            helperText={formik.touched.doctorName && formik.errors.doctorName}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="specilization"
                            name="specilization"
                            label="Specilization"
                            type="text"
                            variant="standard"
                            value={formik.values.specilization}
                            onChange={formik.handleChange}
                            error={formik.touched.specilization && Boolean(formik.errors.specilization)}
                            helperText={formik.touched.specilization && formik.errors.specilization}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="tokens"
                            name="tokens"
                            label="Tokens"
                            type="text"
                            variant="standard"
                            value={formik.values.tokens}
                            onChange={formik.handleChange}
                            error={formik.touched.tokens && Boolean(formik.errors.tokens)}
                            helperText={formik.touched.tokens && formik.errors.tokens}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="city"
                            name="city"
                            label="City"
                            type="text"
                            variant="standard"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="area"
                            name="area"
                            label="Area"
                            type="text"
                            variant="standard"
                            value={formik.values.area}
                            onChange={formik.handleChange}
                            error={formik.touched.area && Boolean(formik.errors.area)}
                            helperText={formik.touched.area && formik.errors.area}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="address"
                            name="address"
                            label="Address"
                            type="text"
                            variant="standard"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="startTime"
                            name="startTime"
                            label="Start Time"
                            type="time"
                            variant="standard"
                            value={formik.values.startTime}
                            onChange={formik.handleChange}
                            error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                            helperText={formik.touched.startTime && formik.errors.startTime}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="endTime"
                            name="endTime"
                            label="End Time"
                            type="time"
                            variant="standard"
                            value={formik.values.endTime}
                            onChange={formik.handleChange}
                            error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                            helperText={formik.touched.endTime && formik.errors.endTime}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="fee"
                            name="fee"
                            label="Fee"
                            type="text"
                            variant="standard"
                            value={formik.values.fee}
                            onChange={formik.handleChange}
                            error={formik.touched.fee && Boolean(formik.errors.fee)}
                            helperText={formik.touched.fee && formik.errors.fee}
                        />



                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


