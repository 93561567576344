import React, { useState } from "react";
import { Button, Card, Box } from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import { Grid, TextField, FormControl,FormControlLabel,FormLabel, InputLabel, Select, MenuItem, } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Radio from '@material-ui//core/Radio';
import RadioGroup from '@material-ui//core/RadioGroup';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useParams } from "react-router-dom";
import DoctorBookingService from "./Locality/Service/doctorBookingService";
import DoctorService from "./Locality/Service/doctorService";
import SpecilizationService from "./Locality/Service/specilizationService";
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import Footer from "../../pages/home/Footer"
import Loader from './Loader';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: 'rgb(255, 107, 129)',
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },

});

export default function DoctorBooking(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [doctorBooking, setDoctorBooking] = useState({
    clinicName: '',
    patientName: '',
    clinicId:'',
    doctorName:'',
    profileRegistrationId: '',
    // specilizationId: '',
    mobile: '',
    fee: '',
    

  });
  const [doctorList, setDoctorList] = useState([]);

  const [totalSum, settotalSum] = useState(0);

  const { doctorId } = useParams();
  const [mobile, setMobile] = useState('');
  const [doctorBookingList, setDoctorBookingList] = useState([]);
  const [profileRegistrationId, setProfileRegistrationId] = useState('');
  var [error, setError] = useState(null);
  const [fee, setFee] = useState([]);
  const [specilizationList, setSpecilizationList] = useState([]);
  const [paymentMode1, setPaymentMode1]= useState('online');
  const validationSchema = Yup.object().shape({
    doctorId: Yup.string().required('doctor Name is required'),

    mobile: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),

    patientName: Yup.string().required('patient Name is required'),
     

  });
  // const handleIncrement = (id) => {
  //   setFee(prevItems =>
  //     prevItems.map(item =>
  //       item.id === id ? { ...item, quantity: item.quantity + 1, totalprice: (item.price * (item.quantity + 1)) } : item
  //     )pecilizationId
  //   );
  //   // sumOfTotal();
  // };
  // const handleDecrement = (id) => {
  //   setFee(prevItems =>
  //     prevItems.map(item =>
  //       item.id === id && item.quantity > 0
  //         ? { ...item, quantity: item.quantity - 1, totalprice: (item.price * (item.quantity - 1)) }
  //         : item
  //     )
  //   );

  // };
  const getDay = () => {
    const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();

    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const currentDayName = daysOfWeek[currentDayNumber];
    return currentDayName;
  }

  const handleOpens = () => {
    props.history.push(`/getqr/${doctorId}`)
  };
  const  paymentMode = (paymentMode) => {
    
    setPaymentMode1(paymentMode);
  };
  const sumOfTotal = () => {
    console.log(JSON.stringify(fee));
    const totalPrice = fee.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    console.log(totalPrice)
  }
  const getSpecilizationList = (registerId) => {
    const specilizationI = { profileRegistrationId: registerId };
    SpecilizationService.getAllSpecilization(specilizationI).then((res) => {
      setSpecilizationList(res);

    }).catch((err) => {
      // setError(err.message);
    });
  }
  const getProfileId = (profileRegistrationId) => {
    
    DoctorService.addAllProfileId({ profileRegistrationId: profileRegistrationId  }).then((res) => {

      setDoctorList(res);
      
    }).catch((err) => {

    });
  }
  const pricePerItem = 10;
  useEffect(() => {
    getByIdList();
   
    //  sumOfTotal();
    return () => {
      setSpecilizationList([]);
    }
  }, []);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  function isObj(val) {
    return typeof val === 'object'
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })

    return form
  }
  function post(details) {
    const form = buildForm(details)
    console.log(form);
    document.body.appendChild(form)
    form.submit()
    form.remove()
    //setLoading(false);
  }
  const payment = (values) => {
    DoctorBookingService.paymentProcess(values).then((response) => {
      var information = {
        action: process.env.REACT_APP_CCAV_URL,
        params: response
      }
      post(information);
    })
  }
  const bookDoctor = () => {
    // const getBookedDetails = fee.filter(feeDetails => feeDetails.quantity > 0);
    // const totalSum = getBookedDetails.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const day = getDay();
    const phoneNumberRegex = /^\d{10}$/;
    if (!mobile) {
      alert("Please enter mobile number");
      return
    }
    if (!phoneNumberRegex.test(mobile)) {
      alert("Please enter valid mobile number");
      return
    }
    if (doctorBooking.holidayDays && doctorBooking.holidayDays.includes(day)) {
      alert("You cant procced to book the ticket. today is holiday ");
      return
    }
    // if (getBookedDetails.length <= 0) {
    //   alert("You need to select atleast one field");
    //   return
    // }

    const doctorDetails = {
      fee: fee,
      totalAmount: totalSum,
      doctorId: doctorId,
      // parkName: doctorBooking.doctorName,
      mobile: mobile,
      patientName: doctorBooking.patientName,
      radio:doctorBooking.radio,
      paymentStatus: "pending",
      profileRegistrationId: profileRegistrationId,
    }
    setLoading(true);
    DoctorBookingService.creteDoctorBooking(doctorDetails).then((res) => {
      setMobile('');
      payment({ amount: totalSum, mobile: mobile, id: res.id })
    })
      .catch((err) => {

        alert(err.response.data.message)
      })
  }
  const updateMobile = (event) => {
    setMobile(event.target.value)
  }
  const getByIdList = () => {
    setLoading(true);
    DoctorService.getClinicById(doctorId).then((res) => {
      const clinic = [];
      if (res) {
        setLoading(false);

        setProfileRegistrationId(res.profileRegistrationId);
        getSpecilizationList(res.profileRegistrationId);
        setDoctorBooking({ clinicName: res.clinicName, clinicId :res._id, doctorName: res.doctorName,
           doctorId :res._id, profileRegistrationId: res.profileRegistrationId,fee:res.fee })

        getProfileId(res.profileRegistrationId);
      }
    }).catch((err) => {
      setError(err.message);
    });
  }
  const handleRedirect = (id,clinicId) => {
        
    props.history.push("/doctorappointment/" + id+"/"+clinicId);
};
  const formik = useFormik({
    initialValues: doctorBooking,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.paymentMode = paymentMode1
      DoctorBookingService.creteDoctorBooking(values).then((res) => {
        
        // alert(res.message);
        handleRedirect(res.id,res.clinicId);
       // paymentMode1();
        // props.history.push('/montessori/login');
      })
        .catch((err) => {
          alert(err.response.data.message);
        })
    },
  });

  // const totalSum = fee.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <>
      {loading ? <Loader /> :


        <Box   >
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"

            style={{ minHeight: '100vh' }}>
            <form onSubmit={formik.handleSubmit} class="form" style={{ width: '100%', margin: '15px' }} >
              <div >
                <Card >
                  <CardHeader
                    avatar={

                      <LocationOnIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }


                    // action={
                    //   <IconButton aria-label="settings">

                    //   </IconButton>
                    // }
                    // titleTypographyProps={{variant:'h6' }}

                    classes={{
                      title: classes.title
                    }}
                    title="Clinic"

                  />
                  {/* <Grid item xs={12}>
                    <PageTitle title="Ticket Booking" />
                  </Grid> */}
                  <CardContent>
                    {(doctorBooking?.isCloakRoom || doctorBooking?.isHolidays || doctorBooking?.holidayDays) ? <Grid item xs={12}>
                      <h4> Note: <span style={{
                        'color': "#0a88e4",
                        'font-size': "13px"
                      }}>
                        {doctorBooking?.isCloakRoom ? <span>1.Cloak room Avilable</span> : ''},{doctorBooking?.isHolidays ? <span>2.Holidays on {doctorBooking?.holidayDays}</span> : ''}
                      </span></h4>

                    </Grid> : ''}

                    <Grid item xs={12} >
                      <TextField
                        margin="dense"
                        id="clinicName"
                        name="clinicName"
                        value={doctorBooking.clinicName}
                        label="Name Of The Clinic"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                   
                  </CardContent>
                </Card>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Card >

                  <CardHeader
                    avatar={

                      <ConfirmationNumberIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }
                    classes={{
                      title: classes.title
                    }}
                    title="Appointment With"

                  />
                  <CardContent>

                    
                      {/* <FormControl variant="standard" fullWidth="true" >
                        <InputLabel id="demo-simple-select-standard-label">Specilization </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          InputProps={{ style: { width: 258 } }}

                          margin="dense"
                          name="specilizationId"
                          label="Specilization"
                          type="text"
                          variant="standard"
                          value={formik.values.specilizationId}
                          onChange={e => {
                            formik.handleChange(e);
                            // getProfileId()
                          }}
                          // onChange={e => {
                          //     formik.handleChange(e);

                          // }}
                          error={formik.touched.specilizationId && Boolean(formik.errors.specilizationId)}
                          helperText={formik.touched.specilizationId && formik.errors.specilizationId}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {specilizationList.map(({ _id, specilization }) => (
                            <MenuItem key={_id} value={_id}>{specilization}</MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    
                    <Grid item xs={12} >
                    <TextField
                        margin="dense"
                        id="doctorName"
                        name="doctorName"
                        value={doctorBooking.doctorName}
                        label="Name Of The Doctor"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                      {/* <FormControl variant="standard" fullWidth="true" >
                        <InputLabel id="demo-simple-select-standard-label">Doctor </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          InputProps={{ style: { width: 258 } }}

                          margin="dense"
                          name="doctorId"
                          label="doctorId"
                          type="text"
                          variant="standard"
                        value={doctorBooking.doctorName}

                          // value={formik.values.doctorId}
                          onChange={e => {
                            formik.handleChange(e);
                            // getProfileId()
                            ///onchange write one function to set
                            // const doctorFee = doctorList.find(docktor => docktor._id === e.target.value);
                            // // doctorFee.fee
                            // settotalSum(doctorFee.fee);
                          }}
                          // onChange={e => {
                          //     formik.handleChange(e);

                          // }}
                          error={formik.touched.doctorId && Boolean(formik.errors.doctorId)}
                          helperText={formik.touched.doctorId && formik.errors.doctorId}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {doctorList.map(({ _id, doctorName }) => (
                            <MenuItem key={_id} value={_id}>{doctorName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      {/* <TextField
                        margin="dense"
                        id="doctorName"
                        name="doctorName"
                        value={doctorBooking.doctorName}
                        label="doctorName"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      /> */}
                       <Grid item xs={12}>
                      <TextField
                        style={{ width: '100%' }}
                        margin="dense"
                        id="patientName"
                        name="patientName"
                        value={doctorBooking.patientName}
                        // value={formik.values.patientName}
                        // value={mobile}
                        // onChange={updateMobile}
                        label="Enter Patient Name"
                        type="text"
                        variant="outlined"
                        onChange={formik.handleChange}
                        error={formik.touched.patientName && Boolean(formik.errors.patientName)}
                        helperText={formik.touched.patientName && formik.errors.patientName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '100%' }}
                        margin="dense"
                        id="mobile"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                        helperText={formik.touched.mobile && formik.errors.mobile}
                        label="Enter Mobile Number"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    </Grid>

                  </CardContent>


                </Card>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Card >

                  <CardHeader
                    avatar={

                      <PaymentIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }
                    classes={{
                      title: classes.title
                    }}
                    title="Amount to be paid"

                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <h4> Sub Total:</h4>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ textAlign: 'end' }}>
                          <h3>  &#x20b9;  {doctorBooking.fee}.00</h3>
                        </div>

                      </Grid>
                      <br></br>
                      <br></br>






                      <Grid  container  spacing={3}
  direction="column"
  alignItems="center">
<Card style={{width:"300px", backgroundColor:"aliceblue"}} >

<CardHeader/>

<CardContent>
   <div style={{
    'text-align': 'center', 
    
}}>
  <h5>Select your payment mode</h5>
  <div  >

 
  
  
  <Grid container spacing={2}  >
                      
                      <Grid item xs={6}>
                    
                    
                      <br></br>
                      <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} type="submit" variant="contained"
                      
                      onClick={(e)=>paymentMode("online")} >Online</Button>
                      </Grid>
                      
                      <Grid item xs={6}>
                      <br></br>
                      <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px', textAlign:'end' }} type="submit" variant="contained"
                       onClick={(e)=>paymentMode("cash")}>Cash</Button>
                     
    </Grid>
    </Grid>
    </div>
   </div>

  
  
</CardContent>


</Card>
</Grid>








                 {/* <div   >
                      <Grid container spacing={2}  >
                      
                      <Grid item xs={6}>
                    
                    
                      <br></br>
                      <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} type="submit" variant="contained"
                      
                      onClick={(e)=>paymentMode("online")} >Online</Button>
                      </Grid>
                      
                      <Grid item xs={6}>
                      <br></br>
                      <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px', textAlign:'end' }} type="submit" variant="contained"
                       onClick={(e)=>paymentMode("cash")}>Cash</Button>
                     
    </Grid>
    </Grid>
    </div> */}
                    </Grid>



                  </CardContent>
                </Card>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'left' }}>
                    {/* <a href="javascript:void(0)" class="btn" ><i class="lni lni-apple"></i> Book More Tickets</a> */}
                    {/* <PublicRoute path="/ticketbooking/:parkId" component={TicketBooking} /> */}

                    <a style={{ fontWeight: 'bold', color: '#ff6b81', fontSize: 'large' }} href="javascript:void(0)" onClick={handleOpens} class="page-scroll active">
                      Get QR </a>
                    <p>(In case QR not generated after payment, click here)</p>
                  </div>
                </Grid>
              </div>
              {/* <Grid item xs={12}>
                <div style={{ marginTop: "2%" }}>

                  <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} type="submit" variant="contained" >Book</Button>
                  </div>

                </div>
              </Grid> */}
            </form>
          </Grid>
        </Box>}
      <Footer link="https://www.ccavenue.com/terms_conditions.jsp#:~:text=Subject%20to%20the%20terms%20of,handling%20fee%2C%20express%20payout%20charges%2C"></Footer>
    </>
  );
}


