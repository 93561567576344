import React, { useEffect, useState } from 'react';
import './AdvertisementBanner.css';

const AdvertisementBanner = ({ 
  imageUrl, 
  heading, 
  description, 
  buttonText, 
  buttonUrl 
}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Trigger the animation when the component loads
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 500); // Delayed animation
  }, []);

  return (
    <div className={`ad-banner ${isVisible ? 'fade-in' : ''}`}>
      <div className="ad-banner-image-wrapper">
        <img className="ad-banner-image" src={imageUrl} alt={heading} />
        <div className="gradient-overlay"></div> {/* Overlay effect */}
      </div>

      <div className="ad-banner-content">
        <h2>{heading}</h2>
        <p>{description}</p>
        <a 
          href={buttonUrl} 
          className="ad-banner-button" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default AdvertisementBanner;
