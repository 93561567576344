import requests from './httpService';
const ConfigurationService = {
  getAllConfiguration() {
    return requests.get(`/configuration/list`);
  },
  getConfiguration(body){
    return requests.post('/configuration/get',body); 
  },
  creteConfiguration(body){
    return requests.post('/configuration/add',body); 
  },
  deleteConfiguration(body){
    return requests.delete(`/configuration/${body._id}`);  
  },
  upadeConfiguration(body) {
    return requests.put(`/configuration/${body._id}`,body); 
  }, 
};

export default ConfigurationService;
