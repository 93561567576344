import requests from './httpService';
const LoanBookingService = {
  getAllLoanBooking() {
    return requests.get(`/loanbooking/list`);
  },
  creteLoanBooking(body){
    return requests.post('/loanbooking/add',body); 
  },

  getAllLoanBookingById(id,body){
    return requests.post(`/loanbooking/list/${id}`,body); 
  },
  
  deleteLoanBooking(body){
    return requests.delete(`/loanbooking/${body._id}`); 
  },
  getLoanBookingById(id) {
    return requests.get(`/loanbooking/${id}` );
  },
  findLoanBookingList(body){
    return requests.post(`/loanbooking/find`,body); 
  },
  upadeLoanBooking(body) {
    return requests.put(`/loanbooking/${body._id}`,body); 
  },
  getAllProfileId(body) {
    return requests.post(`/loanbooking/profileid`,body);
  },
  
  getAllProfileSearch(body) {
    return requests.post(`/loanbooking/search`,body);
  },
  
  getLoanBookingList(body){
    return requests.post(`/loanbooking/report`,body);
  },
  paymentProcess(body){
    return requests.post(`/loanbooking/ccav/payment`,body);
  },
  getQRcodeByMobile(body) {
    return requests.post(`/loanbooking/getqrcodebymobile`,body );
  },
  getAppointmentDetailsById(body) {
    return requests.post(`/loanbooking/getqrcodebystatus`,body );
  },
};

export default LoanBookingService;
