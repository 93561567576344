import React, { useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,  TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell} from "@material-ui/core";
import * as Yup from 'yup';
import { Grid,  TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DoctorService from "./Locality/Service/doctorService";
import SpecilizationService from "./Locality/Service/specilizationService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function Doctor() {
    const tableHeaders = ['Name',
    // 'Specilization','Tokens',"start Time","end Time", 
    'Edit', 'Delete'];
    const classes = useStyles();
    const [doctorList, setDoctorList] = useState([]);
    const [age, setAge] = React.useState('');
    // const [specilizationList, setSpecilizationList] = useState([]);

     const [doctorIdList, setDoctorIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [specilizationList, setSpecilizationList] = useState([]);
    const [doctor, setDoctor] = useState({
        doctorName:'',
        // qualifications:'',
        // specilizationId: '',
        // tokens:'',
        // startTime:'',
        // endTime:'',

    })
    const validationSchema = Yup.object().shape({
        doctorName: Yup.string().required('doctorName is required'),
        // qualifications: Yup.string().required('qualifications is required'),
        // specilizationId:Yup.string().required('specilizationId is required'),
        // tokens: Yup.string().required('tokens is required'),
        // startTime: Yup.string().required('startTime is required'),
        // endTime:Yup.string().required('endTime is required'),
    });
  
    useEffect(() => {
        // getDoctorList();
        // getSpecilizationList();
        getProfileId();
        return () => {
            // setDoctorIdList([]);
            setDoctorList([]);
            // setSpecilizationList([]);
        }
    }, []);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
   
    // const getSpecilizationList = (specilizationId) => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));

    //     const result = userDetails.role==="admin" ? null:userDetails.profileId;

    //     const specilizationI = { specilizationId: specilizationId,profileRegistrationId:result  };
    //     SpecilizationService.getAllSpecilization(specilizationI).then((res) => {
    //         setSpecilizationList(res);
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
 

    // const editClinic = (clinic) => {
    //     const obj = JSON.parse(JSON.stringify(clinic));
    //     obj.doctorId = clinic.doctorId ? clinic.doctorId._id : '';
    //         setClinic(obj);
    //         handleOpen();
    //     }
    const editDoctor = (doctor) => {
        const obj = JSON.parse(JSON.stringify(doctor));
        // obj.specilizationId = doctor.specilizationId ? doctor.specilizationId._id : '';
        setDoctor(obj);
        handleOpen();
    }
    const deleteDoctor = (doctordelete) => {
        if (doctordelete) {
            DoctorService.deleteDoctor(doctordelete).then((res) => {
                //getDoctorList();
                getProfileId();
            }).catch((err) => {
            });
        }
    };
    const getProfileId = ()=>{
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
console.log(userDetails.role)
   const result = userDetails.role==="admin" ? null:userDetails.profileId;
   DoctorService.addAllProfileId({profileRegistrationId:result }).then((res) => {
            setDoctorList(res);
           
    }).catch((err) => {
        // setError(err.message);
    });
}
 
    const formik = useFormik({
        initialValues: doctor,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            if (doctor._id) {
                DoctorService.upadeDoctor(values).then((res) => {
                    handleClose();
                    getProfileId();
                   // getDoctorList();
                    resetForm()
                    alert("Doctor Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                DoctorService.creteDoctor(values).then((res) => {
                    //getDoctorList();
                    getProfileId();
                    resetForm();
                    handleClose();
                    alert(" Doctor Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (

        <>
            <PageTitle title="Doctor" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Doctor
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {doctorList.map((doctor) => (
                                    <TableRow key={doctor._id}>
                                        <TableCell className="pl-3 fw-normal" >{doctor.doctorName}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{doctor.qualifications}</TableCell> */}
                                        {/* <TableCell className="pl-3 fw-normal" >{representiveContact.villageId?.village}</TableCell> */}
                                        {/* <TableCell className="pl-3 fw-normal" >{doctor.specilizationId?.specilization}</TableCell> */}
                                        {/* <TableCell className="pl-3 fw-normal" >{doctor.tokens}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{doctor.startTime}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{doctor.endTime}</TableCell> */}
                                        {/* <TableCell className="pl-3 fw-normal" >{doctor.fee}</TableCell> */}

                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editDoctor(doctor)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteDoctor(doctor)} />
                                        </TableCell>
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Doctor</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                        {/* <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="className"
                                name="classId"
                                label="className"
                                value={formik.values.classId}
                                onChange={formik.handleChange}
                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                helperText={formik.touched.classId && formik.errors.classId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}
                                      
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="doctorName"
                            name="doctorName"
                            label="Doctor Name"
                            type="text"
                            variant="standard"
                            value={formik.values.doctorName}
                            onChange={formik.handleChange}
                            error={formik.touched.doctorName && Boolean(formik.errors.doctorName)}
                            helperText={formik.touched.doctorName && formik.errors.doctorName}
                        />
                         {/* <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="qualifications"
                            name="qualifications"
                            label="Specilization"
                            type="text"
                            variant="standard"
                            value={formik.values.qualifications}
                            onChange={formik.handleChange}
                            error={formik.touched.qualifications && Boolean(formik.errors.qualifications)}
                            helperText={formik.touched.qualifications && formik.errors.qualifications}
                        /> */}
                                
                                {/* <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Specilization </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                InputProps={{ style: { width: 258 } }}
                                
                                margin="dense"
                                name="specilizationId"
                                label="Specilization"
                                type="text"
                                variant="standard"
                                value={formik.values.specilizationId}
                                onChange={e => {
                                    formik.handleChange(e);
                                   // getSpecilizationList(e.target.value)
                                }}
                                // onChange={e => {
                                //     formik.handleChange(e);
                                   
                                // }}
                                error={formik.touched.specilizationId && Boolean(formik.errors.specilizationId)}
                                helperText={formik.touched.specilizationId && formik.errors.specilizationId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {specilizationList.map(({ _id, specilization }) => (
                                    <MenuItem key={_id} value={_id}>{specilization}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        {/* <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="tokens"
                            name="tokens"
                            label="Tokens"
                            type="text"
                            variant="standard"
                            value={formik.values.tokens}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.tokens)}
                            helperText={formik.touched.tokens && formik.errors.tokens}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="startTime"
                            name="startTime"
                            label="Start Time"
                            type="time"
                            variant="standard"
                            value={formik.values.startTime}
                            onChange={formik.handleChange}
                            error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                            helperText={formik.touched.startTime && formik.errors.startTime}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="endTime"
                            name="endTime"
                            label="End Time"
                            type="time"
                            variant="standard"
                            value={formik.values.endTime}
                            onChange={formik.handleChange}
                            error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                            helperText={formik.touched.endTime && formik.errors.endTime}
                        /> */}
                         {/* <TextField
                            InputProps={{ style: { width: 258 } }}
                            margin="dense"
                            id="fee"
                            name="fee"
                            label="Fee"
                            type="text"
                            variant="standard"
                            value={formik.values.fee}
                            onChange={formik.handleChange}
                            error={formik.touched.fee && Boolean(formik.errors.fee)}
                            helperText={formik.touched.fee && formik.errors.fee}
                        />  */}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


