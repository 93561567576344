import requests from './httpService';
const BranchUserService = {
   
  getBranchUser(body){
    return requests.post('/branchuser/get',body); 
  },
  getBranchUserById(id) {
    return requests.get(`/branchuser/${id}` );
  },
  getBranchById(id) {
    return requests.get(`/branch/${id}` );
  },
 
};

export default BranchUserService;
