import React, { useState } from "react";
import {
    Button, FormControl, InputLabel, MenuItem, TableRow, Table, TableHead,
    TableBody, TableCell
} from "@material-ui/core";
import SpecilizationService from "./Locality/Service/specilizationService";
import DoctorService from "../dashboard/Locality/Service/doctorService";
import ClinicService from "../dashboard/Locality/Service/clinicService";
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useEffect } from 'react';
import ticketBookingService from "./Locality/Service/ticketBookingService";
import doctorBookingService from "./Locality/Service/doctorBookingService";
import TablePagination from '@material-ui/core/TablePagination';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';

import Loader from './Loader';
import { useParams } from "react-router-dom";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function DoctorReport() {
    const socket = io('http://localhost:3001'); 

    const [fee, setFee] = useState([]);
    const [mobile, setMobile] = useState('');
    const [tableHeaders, settableHeaders] = useState(['Token Number', 'Name','Mobile No.', 'Status', 'Date', 'Payment Mode']);
    const classes = useStyles();
    // const [doctorId, setDoctor] = useState("");
    const [profileRegistrationId, setProfileRegistrationId] = useState('');
    const [result, setResult] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [specilizationList, setSpecilizationList] = useState([]);
    const [pg, setpg] = React.useState(0);
    const [clinicList, setClinicList] = useState([]);
    const [rpg, setrpg] = React.useState(5);
    const [startDate1, setStartDate1] = useState('');
    const [endDate1, setEndDate1] = useState('');
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [getReport, setGetReport] = useState([]);
    const [doctorId, setDoctorId] = useState("");
    const [open, setOpen] = React.useState(false);
    const [clinicId, setClinicId] = useState("");
    const current = new Date();
    const date = `${current.getFullYear()}-0${current.getMonth() + 1}-${current.getDate()}`;
    const [activity, setActivity] = useState({
        classId: true,
        startDate: '',
        endDate: '',
        description: '',
        activityName: '',
        status: '',
    });
    const [doctorBooking, setDoctorBooking] = useState({
        clinicName: '',
        patientName: '',
        clinicId:"",
        doctorId:'',
        profileRegistrationId: '',
        // specilizationId: '',
        mobile: '',
        fee: '',
    
      });
    const [loading, setLoading] = useState(false);
//   const { doctorId } = useParams();
    useEffect(() => {      
        getProfileId();
        // socket.on('connect', () => {
        //     console.log('Connected to server');
        //   });
      
        //   socket.on('disconnect', () => {
        //     console.log('Disconnected from server');
        //   });
       

        // Listen for events from the server
       

        // Clean up on unmount
        
        return () => {
            socket.disconnect();
            setReportList([]);
            setGetReport([]);
            setClinicList([]);
            // setSpecilizationList([]);
        }
    }, []);
    const getSpecilizationList = (registerId) => {
        const specilizationI = { profileRegistrationId: registerId };
        SpecilizationService.getAllSpecilization(specilizationI).then((res) => {
          setSpecilizationList(res);
    
        }).catch((err) => {
          // setError(err.message);
        });
      }
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const getProfileId = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        ClinicService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            setClinicList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getProfileId = (profileRegistrationId) => {
    
    //     DoctorService.addAllProfileId({ profileRegistrationId: profileRegistrationId  }).then((res) => {
    
    //       setDoctorList(res);
          
    //     }).catch((err) => {
    
    //     });
    //   }
    // const getProfileId = (profileRegistrationId) => {
    
    //     DoctorService.addAllProfileId({ profileRegistrationId: profileRegistrationId  }).then((res) => {
    
    //       setDoctorList(res);
          
    //     }).catch((err) => {
    
    //     });
    //   }
    const button = (values) => {
        // req.body.bookingStatus = 'BOOKED';
        values.bookingStatus = 'complete';
        doctorBookingService.upadeDoctorBooking(values).then((res) => {
            // handleClose();
            getProfileId();
            //getClinicList();
            // resetForm()
            socket.emit('customEvent', { message: 'Hello from client!' });
            alert("Doctor Updated Successfully.");
           
        }).catch((err) => {
        });
    }
 
      const doctorDetails = {
        fee: fee,
        // totalAmount: totalSum,
        doctorId: doctorId,
        // parkName: doctorBooking.doctorName,
        mobile: mobile,
        patientName: doctorBooking.patientName,
        paymentStatus: "pending",
        profileRegistrationId: profileRegistrationId,
      }
    const sumFee = (data) => {
        const mergedFeeDetails = {};
        data.forEach((document) => {
            // Iterate through the fee array of each document
            document.fee.forEach((feeDetail) => {
                const name = feeDetail.name;
                const price = feeDetail.price;
                const qty = feeDetail.quantity;
                if (!mergedFeeDetails[name + ' amount']) {
                    // If the name doesn't exist in the merged object, create it
                    // mergedFeeDetails[name] = price;
                    mergedFeeDetails[name + ' amount'] = qty*price;
                    mergedFeeDetails[name + ' Count'] = qty;
                } else {
                    // If the name already exists, add the price to the existing sum
                    mergedFeeDetails[name + ' amount'] += qty*price;
                    mergedFeeDetails[name + ' Count'] += qty;
                    //  mergedFeeDetails[name] += price;
                }
            });
        });
        return mergedFeeDetails;
    }
    const totalSum = (data) => {
        const mergedFeeDetails = {};
        data.forEach((document) => {
            // Iterate through the fee array of each document
            document.fee.forEach((feeDetail) => {
                const name = feeDetail.name;
                const price = feeDetail.price;
                const qty = feeDetail.quantity;
                if (!mergedFeeDetails[name + ' amount']) {
                    // If the name doesn't exist in the merged object, create it
                    // mergedFeeDetails[name] = price;
                    mergedFeeDetails[name + ' amount'] = qty*price;
                } else {
                    // If the name already exists, add the price to the existing sum
                    mergedFeeDetails[name + ' amount'] += qty*price;
                    //  mergedFeeDetails[name] += price;
                }
            });
        });
        const values = Object.values(mergedFeeDetails); // Extract the values into an array
        const sum = values.reduce((acc, currentValue) => acc + currentValue, 0); // Calculate the sum
        return sum;
    }
    const onSubmit = data => {
        const report = JSON.parse(localStorage.getItem("userDetail"));
        
        let newstartDate1 = startDate1 ? startDate1 : null;
        let newendDate1 = endDate1 ? endDate1 : null;

        const keys = { "clinicId": data ? data : clinicId, "startDate": newstartDate1, 
        "endDate": newendDate1 ? `${newendDate1}T23:59:00.000Z`:'' }
        setLoading(true);
        doctorBookingService.getDoctorBookingList(keys).then((res) => {
         
            if (res.length) {
                // const result = res.map(result => {
                   
                //     return {
                //         Date: result.createdAt,
                //         ...sumFee(result.documents),
                //         'Total Amount': `₹ ${totalSum(result.documents)}`,
                //     }
                // });
                
                // const headerDetails = Object.keys(result[0]).map(key => key);
                
                // settableHeaders(headerDetails);
               
                // excelExport(res);
              
                setGetReport(res);
              
            } else {
                setGetReport([]);
            }
            setLoading(false);
            // setResult(result);
        }).catch((err) => {
            setLoading(false);
        });
    };


   
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    return (
        <>
            <PageTitle title="Doctor Reports" />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={2} columns={12} style={{ margin: 10 }}  >
                                <Grid item xs={6} sm={6} md={3} >
                               
                      <FormControl variant="standard" fullWidth="true" >
                        <InputLabel id="demo-simple-select-standard-label">Clinic </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          InputProps={{ style: { width: 258 } }}

                          margin="dense"
                          name="clinicId"
                          label="clinicId"
                          type="text"
                          variant="standard"
                          value={clinicId}
                          onChange={(e) => setClinicId(e.target.value)}

                        //   onChange={e => {
                        //     formik.handleChange(e);
                        //   }}
                         
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {clinicList.map(({ _id, clinicName }) => (
                            // <MenuItem value={true}>Active</MenuItem>
                            <MenuItem key={_id} value={_id} selected>{clinicName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                     
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    <form className={classes.container} noValidate>
                                        <TextField InputProps={{ style: { width: 150 } }}
                                            id="dob"
                                            name="dob"
                                            label="Start Date"
                                            type="date"
                                            min="2016-11-10"
                                            max="2022-11-10"
                                            value={startDate1}
                                            onChange={e => { setStartDate1(e.target.value) }}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} >
                                    <TextField InputProps={{ style: { width: 120 } }}
                                        id="dob"
                                        name="dob"
                                        label="End Date"
                                        type="date"
                                        min="2016-11-10"
                                        max="2022-11-10"
                                        value={endDate1}
                                        onChange={e => { setEndDate1(e.target.value) }}
                                        // defaultValue="2017-05-24"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} >
                                    <Button style={{ backgroundColor: 'rgb(255 107 129)', color: 'white' }}
                                        type="button" onClick={() => onSubmit()} variant="contained" >
                                        Search</Button>
                                </Grid>
                                {/* <Grid item xs={6} sm={6} md={3}  >
                                <ExportExcel   excelData={result} fileName={'Student Activity'} />
                                </Grid> */}
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            {/* {loading && <Loader />} */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        {getReport.length > 0 ? (
                            <Table className="mb-0">
                                <TableHead >
                                    <TableRow>
                                        
                                        {tableHeaders.map(key => (
                                            <StyledTableCell key={key}>{key}</StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {getReport.slice(pg * rpg, pg * rpg + rpg).map((report) => (
                                        <TableRow key={report._id}>
    
                                                <TableCell className="pl-3 fw-normal" >{report.tokenNumber}</TableCell>
                                                <TableCell className="pl-3 fw-normal" >{report.patientName}</TableCell>
                                                <TableCell className="pl-3 fw-normal" >{report.mobile}</TableCell>
                                                {/* <TableCell className="pl-3 fw-normal" >{report.paymentStatus}</TableCell> */}
                                                <TableCell>
                                        <Button variant="contained" onClick={() => button(report)} size="medium" color="secondary" 
                                        style={{ backgroundColor: '#ff6b81' }}> {report.bookingStatus ?report.bookingStatus:"Booked"} </Button>
                                        </TableCell>
                                        <TableCell className="pl-3 fw-normal" >{report.createdAt}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{report.paymentMode}</TableCell>
                                        </TableRow>

                                    ))}
                                    {/* ))} */}
                                </TableBody>
                            </Table>
                        ) : (
                            <p style={{ textAlign: "center" }}> No data to display.</p>
                        )}

                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 50, 100, 500, 1000]}
                            count={reportList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
        </>
    );
}


