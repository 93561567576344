import requests from './httpService';
const ExbhitionService = {
  getAllExbhition() {
    return requests.get(`/exbhition/list`);
  },
  getExbhitions(body){
    return requests.post('/exbhition/get',body); 
  },
  creteExbhition(body){
    return requests.post('/exbhition/add',body); 
  },
  getAllExbhitionById(id,body){
    return requests.post(`/exbhition/list/${id}`,body); 
  },
  deleteExbhition(body){
    return requests.delete(`/exbhition/${body._id}`); 
    
  },
  getExbhitionById(id) {
    return requests.get(`/exbhition/${id}` );
  },
  getQRcodebyById(body) {
    return requests.post(`/exbhitionbooking/getqrcodebystatus`,body );
  },
  getAppointmentDetailsById(body) {
    return requests.post(`/exbhitionbooking/getqrcodebystatus`,body );
  },

  qrCode(id) {
    return requests.get(`/exbhition/qr/${id}` );
  },
  findExbhitionList(body){
    return requests.post(`/exbhition/find`,body); 
  },
  upadeExbhition(body) {
    return requests.put(`/exbhition/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/exbhition/listbyprofileid`,body);
  },
  
};

export default ExbhitionService;
