import requests from './httpService';
const CounterLoginService = {
  getAllCounterLogin() {
    return requests.get('/counterlogin/list');
  },
  creteCounterLogin(body){
    return requests.post('/counterlogin/add',body); 
  },
  updateCounterBookingStatus(body){
    return requests.put('/loanbooking/'+body._id,body); 
  },
  loginCounterLogin(body){ 
    return requests.post('/counterlogin/login', body);
  },
  getBokingCounterDetails(body){ 
    return requests.post('/loanbooking/profileid', body);
  }
};

export default CounterLoginService;
