import React, { useState } from "react";
import {
    Button, FormControl, InputLabel, MenuItem,
    TableRow, Table,  
    TableHead,
    TableBody, Card,
    TableCell,Box,
} from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import * as Yup from 'yup';
import TicketBookingService from "./Locality/Service/ticketBookingService";

import { Typography } from "../../components/Wrappers/Wrappers";
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useEffect } from 'react';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Loader from './Loader';
import ExportExcel from "../../Excelexport";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function ReportSearch() {
    const tableHeaders = ['Order Id', 'Park Name' , 'Total', 'Mobile','Ticket Scanned','Payment Status','Date'];
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [result, setResult]= useState([]);
    const [parkList, setParkList] = useState([]);
    const [mobile, setMobile] = useState('');
    const [parkId, setParkId] = useState("");
    const [status, setStatus] = useState("");
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [ticketBookingList, setTicketBookingList] = useState([]);
    const [startDate1, setStartDate1] = useState('');
 
    const [park, setPark] = useState({
        parkName: '',
        location: '',
        adult: false,
        child: false,
        adultFee: '',
        childFee: '',
        seniorCitizen: false,
        seniorCitizenFee: '',
        women: false,
        womenFee: '',
        physicallyChallenged: false,
        physicallyChallengedFee: '',
        camera: false,
        cameraFee: '',
        photography: false,
        photographyFee: '',
        shooting: false,
        shootingFee: '',
        walker: false,
        walkerFee: '',
        startTiming: '',
        endTiming: '',
        note: '',
    });
    const [holidays, setHolidaysState] = React.useState({
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
    });
    const [checkboxes, setCheckboxes] = useState({
        adult: false,
        child: false,
        seniorCitizen: false,
        women: false,
        physicallyChallenged: false,
        camera: false,
        photography: false,
        shooting: false,
        isCloakRoom: false,
        isHolidays: false
    });
    const validationSchema = Yup.object().shape({
        parkName: Yup.string().required('park name is required'),
        location: Yup.string().required('location is required'),
        adult: Yup.boolean(),
        child: Yup.boolean(),
        adultFee: Yup.string(),
        childFee: Yup.string(),
        seniorCitizen: Yup.boolean(),
        seniorCitizenFee: Yup.string(),
        women: Yup.boolean(),
        womenFee: Yup.string(),
        physicallyChallenged: Yup.boolean(),
        physicallyChallengedFee: Yup.string(),
        camera: Yup.boolean(),
        cameraFee: Yup.string(),
        photography: Yup.boolean(),
        photographyFee: Yup.string(),
        shooting: Yup.boolean(),
        shootingFee: Yup.string(),
        isCloakRoom: Yup.boolean(),
        isHolidays: Yup.boolean(),
        startTiming: Yup.string(),
        endTiming: Yup.string(),
        node: Yup.string(),
    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
    };

    const handleHolidaysChange = (event) => {
        setHolidaysState({
            ...holidays,
            [event.target.name]: event.target.checked,
        });
    };
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    useEffect(() => {
        // getTicketBookingList();
        //getProfileIdList();
        // getQrCode()
        getProfileId();
        return () => {
              setTicketBookingList([]);
        }
    }, []);

    const getProfileId = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        console.log(userDetails.role)

        const result = userDetails.role === "admin" ? null : userDetails.profileId;
       
        ParkService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            if (res.length > 0) {
                setParkList(res);
                const firstValue = res[0];
                setParkId(firstValue._id);
               
            } else {
                setParkList(res);
            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getTicketBookingList = () => {
        TicketBookingService.getAllTicketBooking().then((res) => {
            setTicketBookingList(res);
           
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            // hour12: true,

        });
        return formattedDateTime;
    }
    const getProfileIdList = ()=>{
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
console.log(mobile)

   const result = userDetails.role==="admin" ? null:userDetails.profileId;

  
   setLoading(true);
   TicketBookingService.getAllProfileSearch(
    {profileRegistrationId:result,
        parkId:parkId,
        paymentStatus:status,
        date:startDate1,
    mobile:mobile }).then((res) => {
            setTicketBookingList(res);
            setLoading(false);
            excelExport(res);
    }).catch((err) => {
        setLoading(false);
        // setError(err.message);
    });
}

    const  excelExport  =(res)  => {
        const result= res.map((response)=>{
 
             return {
                //  "Student Name":response.studentDetails[0] ? response.studentDetails[0].studentName : '', 
                "Order Id":response._id,
                 "Park Name":response.parkId?.parkName,
                 "Mobile":response.mobile,
                 "Ticket Scanned":response.isTicketScanned ? 'Scanned':'Not Scanned',
                 "Payment Status":response.paymentStatus,
                 "Date":response.createdAt,
             }
            
          })
          setResult(result);
     };
  
    return (
        <>
            <PageTitle title=" Reports Search" />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={3} columns={12} style={{ margin: 10 }}  >
                            <Grid item xs={6} sm={6} md={2} >
                                    <FormControl className={classes.formControl}  style={{ width: 150 }}
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Park</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="parkId"
                                            label="Select Park"
                                            value={parkId}
                                            onChange={(e) => setParkId(e.target.value)}

                                        // error={formik.touched.parkId && Boolean(formik.errors.parkId)}
                                        // helperText={formik.touched.parkId && formik.errors.parkId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {parkList.map(({ _id, parkName }) => (
                                                <MenuItem key={_id} value={_id}>{parkName}

                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} >
                                    <FormControl className={classes.formControl} style={{ width: 150 }}
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            label="Select Status"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}

                                        // error={formik.touched.parkId && Boolean(formik.errors.parkId)}
                                        // helperText={formik.touched.parkId && formik.errors.parkId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            
                                                <MenuItem  value="PENDING">
                                                    Pending

                                                </MenuItem>
                                                <MenuItem  value="TXN_SUCCESS">
                                                Success

                                                </MenuItem>
                                                <MenuItem  value="Aborted">
                                                Aborted

                                                </MenuItem>
                                                <MenuItem  value="Awaited">
                                                Awaited

                                                </MenuItem>
                                                <MenuItem  value="Failure">
                                                Failure

                                                </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                        <TextField 
                                            id="mobile"
                                            name="mobile"
                                            label="Enter Mobile"
                                            type="text"
                                            InputProps={{ style: { width: 150 } }}
                                           
                                            onChange={e => { setMobile(e.target.value); }}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                        <TextField InputProps={{ style: { width: 150 } }}
                                            id="dob"
                                            name="dob"
                                            label="Start Date"
                                            type="date"
                                           
                                             value={startDate1}
                                             onChange={e => { setStartDate1(e.target.value) }}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                   
                                </Grid>
                               
                                <Grid item xs={6} sm={6} md={2} >
                                    <Button style={{ backgroundColor: 'rgb(255 107 129)', color: 'white' }}
                                        type="button" onClick={() => getProfileIdList()} variant="contained" >
                                        Search</Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}  >
                                <ExportExcel   excelData={result} fileName={'Payment Status'} />
                                </Grid>
                                
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            {loading && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ticketBookingList.slice(pg * rpg, pg * rpg + rpg).map((ticketBooking, bookingReport) => (
                                    <TableRow key={ticketBooking._id}>

                                       <TableCell className="pl-3 fw-normal" >{ticketBooking._id}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{ticketBooking.parkId?.parkName}</TableCell>
                                       
                                        <TableCell className="pl-3 fw-normal" >{ticketBooking.totalAmount}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{ticketBooking.mobile}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{ticketBooking.isTicketScanned ? 'Scanned':'Not Scanned'}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{ticketBooking.paymentStatus}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{dateAndTime(ticketBooking.createdAt)}</TableCell>



                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 100, 1000]}
                            count={ticketBookingList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>

        </>
    );

}


