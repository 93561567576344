import requests from './httpService';

const DoctorBookingService = {
  getAllDoctorBooking() {
    return requests.get(`/doctorbooking/list`);
  },
  creteDoctorBooking(body){
    return requests.post('/doctorbooking/add',body); 
  },
  getAllDoctorBookingById(id,body){
    return requests.post(`/doctorbooking/list/${id}`,body); 
  },
  deleteDoctorBooking(body){
    return requests.delete(`/doctorbooking/${body._id}`); 
    
  },
  getDoctorBookingById(id) {
    return requests.get(`/doctorbooking/${id}` );
  },

  findDoctorBookingList(body){
    return requests.post(`/doctorbooking/find`,body); 
  },
  upadeDoctorBooking(body) {
    return requests.put(`/doctorbooking/${body._id}`,body); 
  },
  getAllProfileId(body) {
    return requests.post(`/doctorbooking/profileid`,body);
  },
  
  getAllProfileSearch(body) {
    return requests.post(`/doctorbooking/search`,body);
  },
  // router.post('/report', getTicketBookingList);
  
  getDoctorBookingList(body){
    return requests.post(`/doctorbooking/report`,body);
  },
  paymentProcess(body){
    return requests.post(`/doctorbooking/ccav/payment`,body);
  },
  getQRcodeByMobile(body) {
    return requests.post(`/doctorbooking/getqrcodebymobile`,body );
  },

};

export default DoctorBookingService;
