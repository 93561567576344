import React, { useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,  TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell} from "@material-ui/core";
import * as Yup from 'yup';
import { Grid,  TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import SpecilizationService from "./Locality/Service/specilizationService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Specilization() {
    const tableHeaders = ['Specilization', 'Edit', 'Delete'];
    const classes = useStyles();
    const [specilizationList, setSpecilizationList] = useState([]);
    const [age, setAge] = React.useState('');
    const [specilizationIdList, setSpecilizationIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [specilization, setSpecilization] = useState({
        specilization: '',
        status: '',
    })
    const validationSchema = Yup.object().shape({
        specilization: Yup.string().required('specilization is required'),
        status: Yup.string().required('status is required'),
    });
    useEffect(() => {
        getSpecilizationList();
        getProfileId();
        return () => {
            setSpecilizationIdList([]);
            setSpecilizationList([]);
        }
    }, []);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
 
    const getSpecilizationList = () => {
        SpecilizationService.getAllSpecilization().then((res) => {
            setSpecilizationList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
   
    const editSpecilization = (specilization) => {
        setSpecilization(specilization);
        handleOpen();
    }
    const deleteSpecilization = (specilizationdelete) => {
        if (specilizationdelete) {
            SpecilizationService.deleteSpecilization(specilizationdelete).then((res) => {
                getSpecilizationList();
                getProfileId();
            }).catch((err) => {
            });
        }
    };
    const getProfileId = ()=>{
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
console.log(userDetails.role)

   const result = userDetails.role==="admin" ? null:userDetails.profileId;
   console.log(result)
   SpecilizationService.addAllProfileId({profileRegistrationId:result }).then((res) => {
            setSpecilizationList(res);
    }).catch((err) => {
        // setError(err.message);
    });
}
    const formik = useFormik({
        initialValues: specilization,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            if (specilization._id) {
                SpecilizationService.upadeSpecilization(values).then((res) => {
                    handleClose();
                    getProfileId();
                    getSpecilizationList();
                    resetForm()
                    alert("Specilization Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                SpecilizationService.creteSpecilization(values).then((res) => {
                    getSpecilizationList();
                    getProfileId();
                    resetForm();
                    handleClose();
                    alert(" Specilization Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (
        <>
            <PageTitle title="Specilization" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Specilization
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {specilizationList.map((specilization) => (
                                    <TableRow key={specilization._id}>
                                        <TableCell className="pl-3 fw-normal" >{specilization.specilization}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editSpecilization(specilization)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteSpecilization(specilization)} />
                                        </TableCell>
                                        {/* <TableCell>
      <EditIcon   onClick={() => editVendor(category._id)} >
      
      </EditIcon >
    </TableCell> */}
                                        {/* <TableCell>
      <DeleteIcon onClick={() => deleteVendorRister(vendorRegistration)} />
    </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Specilization</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                        {/* <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="className"
                                name="classId"
                                label="className"
                                value={formik.values.classId}
                                onChange={formik.handleChange}
                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                helperText={formik.touched.classId && formik.errors.classId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}
                                      
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="specilization"
                            name="specilization"
                            label="specilization"
                            type="text"
                            variant="standard"
                            value={formik.values.specilization}
                            onChange={formik.handleChange}
                            error={formik.touched.specilization && Boolean(formik.errors.specilization)}
                            helperText={formik.touched.specilization && formik.errors.specilization}
                        />
                                
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="status"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>In Active</MenuItem>

                            </Select>
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


