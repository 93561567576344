import requests from './httpService';
const ExbhitionBookingService = {
  getAllExbhitionBooking() {
    return requests.get(`/exbhitionbooking/list`);
  },
  creteExbhitionBooking(body){
    return requests.post('/exbhitionbooking/add',body); 
  },
  getAllExbhitionBookingById(id,body){
    return requests.post(`/exbhitionbooking/list/${id}`,body); 
  },
  deleteExbhitionBooking(body){
    return requests.delete(`/exbhitionbooking/${body._id}`); 
    
  },
  getExbhitionBookingById(id) {
    return requests.get(`/exbhitionbooking/${id}` );
  },

  findExbhitionBookingList(body){
    return requests.post(`/exbhitionbooking/find`,body); 
  },
  upadeExbhitionBooking(body) {
    return requests.put(`/exbhitionbooking/${body._id}`,body); 
  },
  getAllProfileId(body) {
    return requests.post(`/exbhitionbooking/profileid`,body);
  },
  
  getAllProfileSearch(body) {
    return requests.post(`/exbhitionbooking/search`,body);
  },
  // router.post('/report', getTicketBookingList);
  getExbhitionBookingList(body){
    return requests.post(`/exbhitionbooking/report`,body);
  },
  // paymentProcess(body){
  //   return requests.post(`/exbhitionbooking/ccav/payment`,body);
  // },
  getQRcodeByMobile(body) {
    return requests.post(`/exbhitionbooking/getqrcodebymobile`,body );
  },

};

export default ExbhitionBookingService;
