import React, { useState } from "react";
import { Button, Card, Box } from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import { Grid, TextField, FormControl,FormControlLabel,FormLabel, InputLabel, Select, MenuItem, } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Radio from '@material-ui//core/Radio';
import RadioGroup from '@material-ui//core/RadioGroup';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useParams } from "react-router-dom";
import ExbhitionBookingService from "./Locality/Service/exbhitionBookingService";
import ExbhitionService from "./Locality/Service/exbhitionService";
import SpecilizationService from "./Locality/Service/specilizationService";
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import Footer from "../../pages/home/Footer"
import Loader from './Loader';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: 'rgb(255, 107, 129)',
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },

});

export default function ExbhitionBooking(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [exbhitionBooking, setExbhitionBooking] = useState({
    visitorName: '',
    organizationName:'',
    exbhitionType:'',
    exbhitionId:'',
    profileRegistrationId: '',
    mobile: '',
  });
  const [exbhitionList, setExbhitionList] = useState([]);

  const [totalSum, settotalSum] = useState(0);

  const { exbhitionId } = useParams();
  const [mobile, setMobile] = useState('');
  const [exbhitionIdBookingList, setExbhitionIdBookingList] = useState([]);
  const [profileRegistrationId, setProfileRegistrationId] = useState('');
  var [error, setError] = useState(null);
  const [fee, setFee] = useState([]);
//   const [specilizationList, setSpecilizationList] = useState([]);
//   const [paymentMode1, setPaymentMode1]= useState('online');
  const validationSchema = Yup.object().shape({
    exbhitionId: Yup.string().required('doctor Name is required'),
    mobile: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),

      visitorName: Yup.string().required('visitor name is required'),
  });
 
  const getDay = () => {
    const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();

    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const currentDayName = daysOfWeek[currentDayNumber];
    return currentDayName;
  }

  const handleOpens = () => {
    props.history.push(`/getqr/${exbhitionId}`)
  };
  // const getSpecilizationList = (registerId) => {
  //   const specilizationI = { profileRegistrationId: registerId };
  //   SpecilizationService.getAllSpecilization(specilizationI).then((res) => {
  //     setSpecilizationList(res);

  //   }).catch((err) => {
  //     // setError(err.message);
  //   });
  // }
  const getProfileId = (profileRegistrationId) => {
    
    ExbhitionService.addAllProfileId({ profileRegistrationId: profileRegistrationId  }).then((res) => {

      setExbhitionList(res);
      
    }).catch((err) => {

    });
  }
  const pricePerItem = 10;
  useEffect(() => {
    getByIdList();
   
    //  sumOfTotal();
    return () => {
      setExbhitionList([]);
    }
  }, []);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  function isObj(val) {
    return typeof val === 'object'
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })

    return form
  }
  function post(details) {
    const form = buildForm(details)
    console.log(form);
    document.body.appendChild(form)
    form.submit()
    form.remove()
    //setLoading(false);
  }
 
  const bookDoctor = () => {
    // const getBookedDetails = fee.filter(feeDetails => feeDetails.quantity > 0);
    // const totalSum = getBookedDetails.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const day = getDay();
    const phoneNumberRegex = /^\d{10}$/;
  
    // if (getBookedDetails.length <= 0) {
    //   alert("You need to select atleast one field");
    //   return
    // }

    const doctorDetails = {
     
      exbhitionId: exbhitionId,
      mobile: mobile,
      // visitorName: visitorName,
      organizationName: exbhitionBooking.organizationName,
      profileRegistrationId: profileRegistrationId,
    }
    setLoading(true);
    ExbhitionBookingService.creteExbhitionBooking(doctorDetails).then((res) => {
      setMobile('');
    })
      .catch((err) => {

        alert(err.response.data.message)
      })
  }
  const updateMobile = (event) => {
    setMobile(event.target.value)
  }
  const getByIdList = () => {
    setLoading(true);
    ExbhitionService.getExbhitionById(exbhitionId).then((res) => {
      const exbhition = [];
      if (res) {
        setLoading(false);

        setProfileRegistrationId(res.profileRegistrationId);
        // getSpecilizationList(res.profileRegistrationId);
        setExbhitionBooking({ organizationName: res.organizationName, exbhitionId :res._id,  exbhitionType :res.exhibition, profileRegistrationId: res.profileRegistrationId })
        getProfileId(res.profileRegistrationId);
      }
    }).catch((err) => {
      setError(err.message);
    });
  }
  const handleRedirect = (id,clinicId) => {
        
    props.history.push("/exbhitionappointment/" + id+"/"+exbhitionId);
};
  const formik = useFormik({
    initialValues: exbhitionBooking,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
     
      ExbhitionBookingService.creteExbhitionBooking(values).then((res) => {
        
        // alert(res.message);
        handleRedirect(res.id,res.exbhitionId);
       // paymentMode1();
        // props.history.push('/montessori/login');
      })
        .catch((err) => {
          alert(err.response.data.message);
        })
    },
  });

  // const totalSum = fee.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <>
      {loading ? <Loader /> :


        <Box   >
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"

            style={{ minHeight: '100vh' }}>
            <form onSubmit={formik.handleSubmit} class="form" style={{ width: '100%', margin: '15px' }} >
              <div >
                <Card >
                  <CardHeader
                    avatar={

                      <LocationOnIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }


                    // action={
                    //   <IconButton aria-label="settings">

                    //   </IconButton>
                    // }
                    // titleTypographyProps={{variant:'h6' }}

                    classes={{
                      title: classes.title
                    }}
                    title="Shows"

                  />
                  {/* <Grid item xs={12}>
                    <PageTitle title="Ticket Booking" />
                  </Grid> */}
                  <CardContent>
                   

                    <Grid item xs={12} >
                      <TextField
                        margin="dense"
                        id="organizationName"
                        name="organizationName"
                        value={exbhitionBooking.organizationName}
                        label="Organization Name"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        margin="dense"
                        id="exbhitionId"
                        name="exbhitionId" 
                        value={exbhitionBooking.exbhitionType}
                        //label="Shows"
                        type="text"
                        variant="outlined"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                   
                  </CardContent>
                </Card>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Card >

                  <CardHeader
                    avatar={

                      <ConfirmationNumberIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                    }
                    classes={{
                      title: classes.title
                    }}
                    title="Personal Details"

                  />
                  <CardContent>
                    <Grid item xs={12} >
                    
                     
                       <Grid item xs={12}>
                      <TextField
                        style={{ width: '100%' }}
                        margin="dense"
                        id="visitorName"
                        name="visitorName"
                        value={formik.values.visitorName}                                      
                        label="Enter Full Name"
                        type="text"
                        variant="outlined"
                        onChange={formik.handleChange}
                        error={formik.touched.visitorName && Boolean(formik.errors.visitorName)}
                        helperText={formik.touched.visitorName && formik.errors.visitorName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '100%' }}
                        margin="dense"
                        id="mobile"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                        helperText={formik.touched.mobile && formik.errors.mobile}
                        label="Enter Mobile Number"
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    </Grid>

                  </CardContent>


                </Card>
              </div>
              
              
              <Grid item xs={12}>
                <div style={{ marginTop: "2%" }}>

                  <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} type="submit" variant="contained" >Book</Button>
                  </div>

                </div>
              </Grid>
            </form>
          </Grid>
        </Box>}
      <Footer link="https://www.ccavenue.com/terms_conditions.jsp#:~:text=Subject%20to%20the%20terms%20of,handling%20fee%2C%20express%20payout%20charges%2C"></Footer>
    </>
  );
}


