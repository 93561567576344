import React from "react";
import { withRouter } from "react-router-dom";
import './Footer.css';
import './styles/bootstrap.min.css';
import './styles/animate.css';
import './styles/tiny-slider.css';
import './styles/glightbox.min.css';
import './styles/main.css';
function Footer(props) {
  return (
    <>
    <footer class="footer">
    <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <nav id="footer-navigation" class="site-navigation footer-navigation centered-box" role="navigation">
                <ul id="footer-menu" class="nav-menu styled clearfix inline-inside">
                  
                  <li id="menu-item-26" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26"><a href={props.link} 
                  >Privacy Policy</a></li>
                 
                </ul>
              </nav>
            </div>
            

         

          </div>
        </div>
      </div>
        <div class="footer-top">
            <div class="container">
            <p style={{textAlign: "center"}}>Copyright © 2012 - 2029 SriQR.com All rights reserved.</p>
           
            </div>
        </div>
        
       
    </footer>
    </>
  );
}

export default withRouter(Footer);
