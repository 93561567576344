import requests from './httpService';
const SpecilizationService = {
  getAllSpecilization(data) {
    return requests.post(`/specilization/get`,data);
  },
  getSpecilization(body){
    return requests.post('/specilization/get',body); 
  },
  creteSpecilization(body){
    return requests.post('/specilization/add',body); 
  },
  getAllSpecilizationById(id,body){
    return requests.post(`/specilization/list/${id}`,body); 
  },
  deleteSpecilization(body){
    return requests.delete(`/specilization/${body._id}`); 
    
  },
  getSpecilizationById(id) {
    return requests.get(`/specilization/${id}` );
  },

  upadeSpecilization(body) {
    return requests.put(`/specilization/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/specilization/listbyprofileid`,body);
  },
  // router.post('/get', getSpecilization);
  // getSpecilization(body){
  //   return requests.post('/specilization/get',body); 
  // },
};

export default SpecilizationService;
