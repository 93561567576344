import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell, Popper, Box,
} from "@material-ui/core";

import * as Yup from 'yup';
import { Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import BranchService from "./Locality/Service/branchService";
import BranchUserService from "./Locality/Service/branchUserService";
import DialogContentText from '@material-ui/core/DialogContentText';
// import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Branch() {
    const tableHeaders = ['Branch Name', 'Branch Code', 'Edit', 'Delete', 'QR code', 'Excutive Officer Detailes'];
    const tableHeaders1 = [ 'Excutive Officer Name', 'Excutive Officer Number'];

    const classes = useStyles();
    const [branchList, setBranchList] = useState([]);
    const [branchUserList, setBranchUserList] = useState([]);
    const [age, setAge] = React.useState('');
    const [branchIdList, setBranchIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [doctorList, setDoctorList] = useState([]);
    const [qrCode, setQrCode] = useState([]);
    const [anchorEl, setAnchorEl] = useState([]);
    const [branch, setBranch] = useState({
        branchName: '',
        branchCode: '',
        counterNumber: '',
        excutiveOfficerName: '',
        excutiveOfficerMobile: '',
        counterNumber:'' 
    })
    const [formValues, setFormValues] = useState([{ excutiveOfficerMobile: "", excutiveOfficerName: "",counterNumber:''  }]);

    const validationSchema = Yup.object().shape({
        branchName: Yup.string().required('branch Name is required'),
        // counterNumber: Yup.string().required('counter Number is required'),
        branchCode: Yup.string().required('branchCode is required'),

    });

    useEffect(() => {
        // getClinicList();
        // getDoctorList();
        getProfileId();
        return () => {
            setBranchIdList([]);
            setBranchList([]);
            setBranchUserList([]);
            // setDoctorList([]);
            // setDoctorIdList([]);
            setQrCode([]);
        }
    }, []);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen1 = () => {
        setOpen1(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleChange = (i, e) => {
        const newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };
    const addFormFields = () => {
        setFormValues([...formValues, { excutiveOfficerMobile: "", excutiveOfficerName: "",counterNumber:'' }]);
        console.log(formValues);
    };

    const removeFormFields = (i) => {
        const newFormValues = [...formValues];
        if (i !== 0) {
            newFormValues.splice(i, 1);
            setFormValues(newFormValues);
        } else if (i === 0 && newFormValues.length === 1) {
            setFormValues([{ type: "", name: "" }]);
        } else if (i === 0 && newFormValues.length > 1) {
            newFormValues.shift();
            setFormValues(newFormValues);
        }
    };

    const getBranchUserList = (branchId) => {
        BranchUserService.getBranchUser({ branchId: branchId }).then((res) => {
            setBranchUserList(res);
            handleOpen1();
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getProfileId = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        BranchService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            setBranchList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }

    const editBranch = (branch) => {
        setBranch(branch);
        handleOpen();
    }
    const deleteBranch = (branchdelete) => {
        if (branchdelete) {
            BranchService.deleteBranch(branchdelete).then((res) => {
                // getClinicList();
                getProfileId()
            }).catch((err) => {
            });
        }
    };
    const getQrCode = (data) => {

        BranchService.qrCode(data._id).then((res) => {

            if (res) {
                const base64Data = res.image.replace(/^data:image\/\w+;base64,/, '');
                const imageBuffer = Buffer.from(base64Data, 'base64');
                // Create a blob URL for the binary buffer
                const blob = new Blob([imageBuffer]);
                const blobUrl = URL.createObjectURL(blob);
                // Create a link element and trigger the download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = data.branchName + '.png'; // Change the file name and extension
                link.click();

                // Clean up the blob URL
                URL.revokeObjectURL(blobUrl);

            }
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const formik = useFormik({
        initialValues: branch,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;

            if (branch._id) {
                BranchService.upadeBranch(values).then((res) => {
                    handleClose();
                    getProfileId();
                    //getClinicList();
                    resetForm()
                    alert("Branch Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                values.counterList = formValues;
                BranchService.creteBranch(values).then((res) => {
                    //getClinicList();
                    getProfileId();
                    resetForm();
                    handleClose();
                    alert(" Branch Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (

        <>
            <PageTitle title="Branch" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Branch
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {branchList.map((branch) => (

                                    <TableRow key={branch._id}>
                                        <TableCell className="pl-3 fw-normal" >{branch.branchName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{branch.branchCode}</TableCell>


                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editBranch(branch)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteBranch(branch)} />
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="contained" onClick={() => getQrCode(branch)} size="medium" color="secondary"
                                                style={{ backgroundColor: '#ff6b81' }}> Download </Button>
                                            {/* <QrCodeIcon style={{ cursor: 'pointer' }} onClick={() => getQrCode(park)} /> */}
                                        </TableCell>
                                       
                                       
                                        <TableCell >
                                            <Button variant="outlined" color="primary" 
                                             onClick={() => getBranchUserList(branch._id)} >
                                                View
                                            </Button>
                           
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* {branchUserList.map(({ _id, cityName }) => (
                  <MenuItem key={_id} value={_id}>{cityName}</MenuItem>
                ))} */}
                        
                    </Widget>
                </Grid>
            </Grid>
            <Dialog
                            open={open1}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Excutive Office Detailes"}</DialogTitle>
                            <DialogContent>

                                <DialogContentText id="alert-dialog-description">
                                <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders1.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {branchUserList.map((branch) => (

                                    <TableRow key={branch._id}>
                                        {/* counterUsername, counterMobile */}
                                        <TableCell className="pl-3 fw-normal" >{branch.counterUsername}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{branch.counterMobile}</TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                                {/* {branchUserList.map(({ counterMobile, counterUsername }))} */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleClose1} color="primary"  autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Branch</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 408 }}>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="branchName"
                            name="branchName"
                            label="Branch Name"
                            type="text"
                            variant="standard"
                            value={formik.values.branchName}
                            // value={formik.values.branchName}
                            onChange={formik.handleChange}
                            error={formik.touched.branchName && Boolean(formik.errors.branchName)}
                            helperText={formik.touched.branchName && formik.errors.branchName}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="branchCode"
                            name="branchCode"
                            label="Branch Code"
                            type="text"
                            variant="standard"
                            value={formik.values.branchCode}
                            // value={formik.values.branchName}
                            onChange={formik.handleChange}
                            error={formik.touched.branchCode && Boolean(formik.errors.branchCode)}
                            helperText={formik.touched.branchCode && formik.errors.branchCode}
                        />
                        {/* <TextField
                            InputProps={{ style: { width: 258 } }}

                            margin="dense"
                            id="counterNumber"
                            name="counterNumber"
                            label="Counter Number"
                            type="text"
                            variant="standard"
                            value={formik.values.counterNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.counterNumber && Boolean(formik.errors.counterNumber)}
                            helperText={formik.touched.counterNumber && formik.errors.counterNumber}
                        /> */}
                        <br></br>
                        <span style={{ fontSize: '17px', color: 'rgb(16 182 128)' }} >Add Excutive Office:</span>
                        {formValues.map((element, index) => (
                            <div className="form-inline" key={index}>

<TextField
                                    InputProps={{ style: { width: 258 } }}
                                    margin="dense"
                                    id="counterNumber"
                                    name="counterNumber"
                                    label="counter number"
                                    type="text"
                                    variant="standard"
                                    // value={element.type || ""}
                                    //  value={formik.values.excutiveOfficerName}
                                    // onChange={formik.handleChange}
                                    // onChange={e => { formik.handleChange(index, e);

                                    //    }}
                                    onChange={(e) => handleChange(index, e)}

                                />
                                <TextField
                                    InputProps={{ style: { width: 258 } }}
                                    margin="dense"
                                    id="excutiveOfficerName"
                                    name="excutiveOfficerName"
                                    label="Excutive Office Name"
                                    type="text"
                                    variant="standard"
                                    // value={element.type || ""}
                                    //  value={formik.values.excutiveOfficerName}
                                    // onChange={formik.handleChange}
                                    // onChange={e => { formik.handleChange(index, e);

                                    //    }}
                                    onChange={(e) => handleChange(index, e)}

                                />
                                <TextField
                                    InputProps={{ style: { width: 258 } }}
                                    margin="dense"
                                    id="excutiveOfficerMobile"
                                    name="excutiveOfficerMobile"
                                    label="Mobile Number"
                                    type="text"
                                    variant="standard"
                                    //value={element.type || ""}
                                    onChange={(e) => handleChange(index, e)}

                                />



                                <AddIcon style={{ cursor: 'pointer', marginLeft: "30px" }} onClick={addFormFields} > </AddIcon >
                                <RemoveIcon style={{ cursor: 'pointer', marginLeft: "20px" }} onClick={() => removeFormFields(index)} ></RemoveIcon>

                            </div>
                        ))}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


